import CircularsList from "@views/components/user/circulars/circulars_list";
import { Box } from "native-base";
import React from "react";

const Circulars = () => {
  return (
    <Box flex={1}>
      <CircularsList />
    </Box>
  );
};
export default Circulars;
