import React from "react";
import { Button, Col, Form, Row } from "antd";
import { Select } from "antd";
import { Box, HStack } from "native-base";
import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  create_event_mutation,
  create_announcement_mutation,
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  employee_list,
  useDynamicSelector,
  get_all_units,
  department_list,
  get_all_designation,
  get_one_announcement_query,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { getUUID, navigateBack, retrieveItem } from "@helpers/functions";
import Audience from "../dynamic_fields/announcement_form_elements/audience";
import Unit from "../dynamic_fields/announcement_form_elements/unit";
import Department from "../dynamic_fields/announcement_form_elements/department";
import Designation from "../dynamic_fields/announcement_form_elements/designation";
import Employee from "../dynamic_fields/announcement_form_elements/employee";
import AnnouncementTitle from "./announcement_title";
import AnnouncementDescription from "./announcement_description";
import { navigate } from "@helpers/navigator";
import { useTranslation } from "react-i18next";
import CustomSelect from "../custom_selects/custom_selects";
const { Option } = Select;

const EditAnnouncement = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [initial_data, setInitialData] = useState({});
  const [selectedValue, setSelectedValue] = useState(null);
  const [showUnitSelect, setShowUnitSelect] = useState(false);
  const [showDepartmentSelect, setShowDepartmentSelect] = useState(false);
  const [showDesignationSelect, setShowDesignationSelect] = useState(false);
  const [showEmployeeSelect, setShowEmployeeSelect] = useState(false);
  const audience_watch = Form.useWatch("audience", form);
  console.log("audience_watch", audience_watch);

  const {
    status: create_announcement_status,
    error: create_announcement_error,
    loading: create_announcement_loading,
  } = useDynamicSelector("create_announcement");
  const { items: unit_items } = useDynamicSelector("get_unit_list");
  const { items: department_lists } = useDynamicSelector("get_department_list");
  const { items: get_all_employee_list } =
    useDynamicSelector("get_employee_list");
  const { items: designation_items } = useDynamicSelector(
    "get_designation_list"
  );

  const data = useDynamicSelector("get_announcement");

  const audience_items = [
    {
      id: "unit",
      name: "Unit",
    },
    {
      id: "designation",
      name: "Designation",
    },
    {
      id: "department",
      name: "Department",
    },
    {
      id: "employee",
      name: "Employee",
    },
  ];

  const handleFirstSelectChange = (value) => {
    setSelectedValue(value);

    setShowUnitSelect(
      value === "unit" ||
        value === "department" ||
        value === "designation" ||
        value === "employee"
    );
    setShowDepartmentSelect(value === "department" || value === "employee");
    setShowDesignationSelect(
      value === "designation" || value === "employee" || value === "department"
    );
    setShowEmployeeSelect(value === "employee");
  };

  const handleSaveClick = (values) => {
    console.log("Save button clicked!", values);
  };

  const handleClick = () => {
    navigate("/events");
  };

  const getAllEmployee = (unit_ids, department_ids) => {
    let key = [{ key: "get_employee_list", loading: true }];
    let query = employee_list;
    let variable = {
      filter: {
        unit_ids,
        department_ids,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllUnits = () => {
    let key = [{ key: "get_unit_list", loading: true }];
    let query = get_all_units;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAllDepartment = (unit_ids) => {
    const user_id = retrieveItem("user_id");
    const current_role = retrieveItem("role");

    let key = [{ key: "get_department_list", loading: true }];
    let query = department_list;
    let variable = {
      filter: {
        unit_ids: current_role === "Unit Admin" ? [user_id] : unit_ids,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllDesignation = () => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneAnnouncement = () => {
    let key = [{ key: "get_announcement", loading: true }];
    let query = get_one_announcement_query;
    let variables = { id: id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const createAnnouncement = (values) => {
    let desiredValue;
    if (values?.file) {
      let splitUrl = values?.file?.[0]?.url?.split("/");
      desiredValue = splitUrl[splitUrl?.length - 1];
    }
    let uuid = getUUID();
    let key = [{ key: "create_announcement", loading: true }];
    let query = create_announcement_mutation;
    let variables = {
      data: {
        title: values?.announcement_title,
        file: `announcement/${desiredValue}` || null,
        id: uuid,
        content: values?.announcement_content,
        notification: {
          audience: values?.audience,
          unit_ids: values?.units,
          department_ids: values?.departments,
          designation_ids: values?.designations,
          employee_ids: values?.employees,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (create_announcement_status === "success") {
      navigateBack();
    }
  }, [create_announcement_status]);

  useEffect(() => {
    if (id) {
      setInitialData({
        ...data,
        announcement_title: data?.title,
        announcement_content: data?.content,
        audience:
          data?.notification?.employees?.length > 0
            ? "employee"
            : data?.notification?.departments?.length > 0
            ? "department"
            : data?.notification?.designations?.length > 0
            ? "designation"
            : data?.notification?.units?.length > 0
            ? "unit"
            : "",
        units: data?.notification?.units?.map((e) => e.name),
        designations: data?.notification?.designations?.map((e) => e.name),
        departments: data?.notification?.departments?.map((e) => e.name),
        employees: data?.notification?.employees?.map((e) => e.name),
        file: data?.file
          ? [
              {
                uuid: "-1",
                name: "Document",
                status: "Done",
                url: data?.file,
              },
            ]
          : [],
      });
      setShowUnitSelect(data?.notification?.units.length > 0 ? true : false);
      setShowDesignationSelect(
        data?.notification?.designations.length > 0 ? true : false
      );
      setShowDepartmentSelect(
        data?.notification?.departments.length > 0 ? true : false
      );
      setShowEmployeeSelect(
        data?.notification?.employees.length > 0 ? true : false
      );
      form.setFieldsValue(initial_data);
    } else {
      form.resetFields();
      setInitialData({});
    }
  }, [id, data]);

  const handleSubmit = (values) => {
    createAnnouncement(values);
  };
  const handleUnitChange = (values) => {
    getAllEmployee(values);
    getAllDepartment(values);
  };
  const handleDepartmentChange = (values) => {
    getAllEmployee([], values, []);
  };
  const handleDesignationChange = (values) => {
    console.log("values", values);
  };
  const handleEmployeeChange = (values) => {
    console.log("values", values);
  };

  useEffect(() => {
    getAllDesignation();
    getAllUnits();
    getAllDepartment();
  }, []);

  useEffect(() => {
    getOneAnnouncement();
  }, []);

  return (
    <Box>
      <AntdDynamicForm
        layout="vertical"
        initialValues={initial_data}
        form={form}
        onSubmit={handleSubmit}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            width: "100%",
          }}
        >
          <h2>{data?.id ? "Announcement Details" : "ANNOUNCEMENT"}</h2>
          <AnnouncementTitle />
          <AnnouncementDescription />
          <Row>
            <Col>
              <AntdDynamicForm.FileUpload
                field={"file"}
                label={t("file_upload")}
                maxCount={1}
                upload_type={"announcement"}
              />
            </Col>
          </Row>
          {/* <Row gutter={[13][13]} style={{ gap: "10px" }}>
            <Col span={11}>
              <Audience
                handleFirstSelectChange={handleFirstSelectChange}
                AudienceOptions={audience_items}
              />
            </Col>
          </Row> */}
          {/* <Row gutter={[13][13]} style={{ gap: "10px" }}>
            {showUnitSelect && (
              <Col span={11}>
                <Unit UnitOptions={unit_items} onChange={handleUnitChange} />
              </Col>
            )}
            {showDepartmentSelect && (
              <Col span={11}>
                <Department DepartmentOption={department_lists} />
              </Col>
            )}
          </Row>
          <Row gutter={[13][13]} style={{ gap: "10px" }}>
            {showDesignationSelect && (
              <Col span={11}>
                <Designation DesignationOption={designation_items} />
              </Col>
            )}
            {showEmployeeSelect && (
              <Col span={11}>
                <Employee EmployeeOptions={get_all_employee_list} />
              </Col>
            )}
          </Row> */}

          <CustomSelect
            handleFirstSelectChange={handleFirstSelectChange}
            AudienceOptions={audience_items}
            showUnitSelect={showUnitSelect}
            showDepartmentSelect={showDepartmentSelect}
            showDesignationSelect={showDesignationSelect}
            showEmployeeSelect={showEmployeeSelect}
            unit_items={unit_items}
            department_lists={department_lists}
            designation_items={designation_items}
            get_all_employee_list={get_all_employee_list}
            handleUnitChange={handleUnitChange}
            handleDepartmentChange={handleDepartmentChange}
            handleDesignationChange={handleDesignationChange}
            handleEmployeeChange={handleEmployeeChange}
            initial_data={initial_data}
          />
        </Box>
        <HStack
          space={5}
          width={"95%"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Box marginBottom={10} alignItems={"center"}>
            <Button
              onClick={() => {
                navigateBack();
              }}
            >
              Back
            </Button>
          </Box>
          {data?.id ? (
            " "
          ) : (
            <Box marginBottom={10} alignItems={"center"}>
              <Button
                type="primary"
                loading={create_announcement_loading}
                htmlType="submit"
              >
                Send
              </Button>
            </Box>
          )}
        </HStack>
      </AntdDynamicForm>
    </Box>
  );
};
export default EditAnnouncement;
