import AdminLogin from "@views/components/user/auth/admin_login";
import { Box, HStack, Link, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import vertace_logo from "@assets/icons/vertace_logo.png";

const AdminLoginPage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Box flex={1}>
      <AdminLogin />
      <Box
        backgroundColor={"#fff"}
        position={"absolute"}
        bottom={0}
        width={"100%"}
        opacity={0.5}
        alignItems={"flex-end"}
      >
        <HStack alignItems={"center"}>
          <Text color="black" fontSize={12}>
            Powered By
          </Text>
          <a
            style={{
              marginBottom: 3,
            }}
            href="https://vertace.com/"
            target="blank"
          >
            <img height="20" alt="vertace" src={vertace_logo} />
          </a>
        </HStack>
      </Box>
    </Box>
  );
};
export default AdminLoginPage;
