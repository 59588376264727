import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import React from "react";

const Department = (props) => {
  const { DepartmentOption, onChange } = props;
  return (
    <AntdDynamicForm.Select
      options={DepartmentOption}
      label="Department"
      field={"departments"}
      isMulti
      onChange={onChange}
      labelCol={{ span: 2 }}
      wrapperCol={{ span: 20 }}
      rules={[
        {
          required: true,
          message: "Department is required!",
        },
      ]}
    />
  );
};

export default Department;
