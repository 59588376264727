import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import React from "react";

const EventTitle = () => {
  return (
    <AntdDynamicForm.TextBox
      label="Event title"
      field={"title"}
      rules={[
        {
          required: true,
          message: "event_title is required!",
        },
      ]}
    />
  );
};
export default EventTitle;
