import React, { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  Row,
  Select,
  Space,
  Col,
  message,
} from "antd";
import { Box, HStack, VStack } from "native-base";
// const [get_one_id, setGetOneId] = useState("");
import {
  display,
  flex,
  justifyContent,
  marginBottom,
  marginTop,
} from "styled-system";
import { Header } from "antd/lib/layout/layout";

import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
// import SurveyElementTitle from "./survey_form_element/survey_element_title";

import { useDispatch } from "react-redux";
import {
  create_survey_mutation,
  department_list,
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  employee_list,
  get_all_designation,
  get_all_units,
  useDynamicSelector,
} from "@services/redux";
import SurveyAllFormElements from "./survey_elements/survey_all_form_elements";
import JoditEditor from "jodit-react";
import { toast } from "react-hot-toast";
import { getUUID, navigateBack } from "@helpers/functions";
import Unit from "../dynamic_fields/announcement_form_elements/unit";
import Department from "../dynamic_fields/announcement_form_elements/department";
import Designation from "../dynamic_fields/announcement_form_elements/designation";
import Audience from "../dynamic_fields/announcement_form_elements/audience";
import Employee from "../dynamic_fields/announcement_form_elements/employee";
import CustomSelect from "../custom_selects/custom_selects";

const SurveyElementDetails = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { TextArea } = Input;
  const history = useHistory();
  const [form] = Form.useForm();

  const [initial_data, setInitialData] = useState({});
  const [selectedValue, setSelectedValue] = useState(null);
  const [showUnitSelect, setShowUnitSelect] = useState(false);
  const [showDepartmentSelect, setShowDepartmentSelect] = useState(false);
  const [showDesignationSelect, setShowDesignationSelect] = useState(false);
  const [showEmployeeSelect, setShowEmployeeSelect] = useState(false);
  const [unit, setUnit] = useState("");
  const [option, setOption] = useState({});

  const { items: unit_items } = useDynamicSelector("get_unit_list");
  const { items: department_lists } = useDynamicSelector("get_department_list");
  const { items: get_all_employee_list } =
    useDynamicSelector("get_employee_list");
  const { items: designation_items } = useDynamicSelector(
    "get_designation_list"
  );

  const { status: create_survey_status, loading: create_survey_loading } =
    useDynamicSelector("create_survey");

  const audience_items = [
    {
      id: "unit",
      name: "Unit",
    },
    {
      id: "designation",
      name: "Designation",
    },
    {
      id: "department",
      name: "Department",
    },
    {
      id: "employee",
      name: "Employee",
    },
  ];
  const handleFirstSelectChange = (value) => {
    setSelectedValue(value);

    setShowUnitSelect(
      value === "unit" ||
        value === "department" ||
        value === "designation" ||
        value === "employee"
    );
    setShowDepartmentSelect(value === "department" || value === "employee");
    setShowDesignationSelect(
      value === "designation" || value === "employee" || value === "department"
    );
    setShowEmployeeSelect(value === "employee");
  };
  const onFinish = (values) => {
    createSurvey(values);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const handleGoBack = () => {
    history.push(ROUTES.SURVEY);
  };

  const getAllEmployee = (unit_ids, department_ids) => {
    let key = [{ key: "get_employee_list", loading: true }];
    let query = employee_list;
    let variable = {
      filter: {
        unit_ids,
        department_ids,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllUnits = () => {
    let key = [{ key: "get_unit_list", loading: true }];
    let query = get_all_units;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAllDepartment = (unit_ids) => {
    let key = [{ key: "get_department_list", loading: true }];
    let query = department_list;
    let variable = {
      filter: {
        unit_ids,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllDesignation = () => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const createSurvey = (values) => {
    let _uuid = getUUID();
    let newData = {
      questions: values.questions.map((question) => ({
        ...question,
        id: getUUID(),
        options: question.options.map((option, index) => ({
          ...option,
          id: getUUID(),
          sort_order: index + 1,
        })),
      })),
    };
    let key = [{ key: "create_survey", loading: true }];
    let query = create_survey_mutation;
    let variables = {
      data: {
        title: values?.title,
        id: _uuid,
        questions: newData?.questions,
        notification: {
          audience: values?.audience,
          unit_ids: values?.units,
          department_ids: values?.departments,
          designation_ids: values?.designations,
          employee_ids: values?.employees,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (create_survey_status === "success") {
      navigateBack();
    }
  }, [create_survey_status]);

  useEffect(() => {
    if (id) {
      let getOneItem = items?.find((x) => {
        return x?.id === id;
      });
      setInitialData({ getOneItem });
      form.setFieldsValue(getOneItem);
    }
  }, [id]);

  const handleUnitChange = (values) => {
    getAllEmployee(values);
    getAllDepartment(values);
  };
  const handleDepartmentChange = (values) => {
    getAllEmployee([], values);
  };
  const handleDesignationChange = (values) => {
    console.log("values", values);
  };
  const handleEmployeeChange = (values) => {
    console.log("values", values);
  };

  useEffect(() => {
    getAllDesignation();
    getAllUnits();
  }, []);

  return (
    <Box style={{ display: "flex", justifyContent: "center" }} mx={10} my={5}>
      <Card style={{ width: "100%", borderRadius: "20px" }}>
        <Box my={3} mx={3} fontSize={20} fontWeight={10}>
          <h5>Add Survey</h5>
        </Box>

        <Box width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            style={{ width: "100%" }}
            form={form}
            initialValues={initial_data}
          >
            <Form.Item
              label="Survey Title"
              name={"title"}
              rules={[
                { required: true, message: t("survey_title_is_required") },
              ]}
            >
              <Input type="text" />
            </Form.Item>
            <Card style={{ width: "100%", borderRadius: "20px" }}>
              <Form.List name="questions">
                {(
                  questionFields,
                  { add: addQuestion, remove: removeQuestion }
                ) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() => addQuestion()}
                        icon={<PlusOutlined />}
                      >
                        Add Question
                      </Button>
                    </div>
                    {questionFields.map((questionField, questionIndex) => (
                      <div key={questionField.key}>
                        <Form.Item
                          label="Survey Question"
                          name={[questionField.name, "content"]}
                          // rules={[
                          //   {
                          //     required: true,

                          //     message: t("survey_content_is_required"),
                          //   },
                          // ]}
                        >
                          {/* <div rows={4} width={"100%"} /> */}

                          <JoditEditor />
                        </Form.Item>
                        <Form.List name={[questionField.name, "options"]}>
                          {(
                            optionFields,
                            { add: addOption, remove: removeOption }
                          ) => (
                            <>
                              {optionFields.map((optionField, optionIndex) => (
                                <HStack>
                                  <Box
                                    key={optionField.key}
                                    style={{
                                      width: "50%",
                                    }}
                                  >
                                    <Form.Item
                                      style={{ width: "100%" }}
                                      {...optionField}
                                      name={[optionField.name, "option"]}
                                      rules={[
                                        {
                                          required: true,

                                          message: "Missing Option",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Option" />
                                    </Form.Item>
                                  </Box>
                                  {optionIndex > 0 && (
                                    <Box
                                      style={{
                                        marginLeft: 5,
                                        marginTop: 10,
                                      }}
                                    >
                                      <MinusCircleOutlined
                                        onClick={() =>
                                          removeOption(optionField.name)
                                        }
                                      />
                                    </Box>
                                  )}
                                </HStack>
                              ))}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  type="dashed"
                                  onClick={() => addOption()}
                                  icon={<PlusOutlined />}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  Add Option
                                </Button>
                              </div>
                            </>
                          )}
                        </Form.List>

                        {questionIndex > 0 && (
                          <Box>
                            <Button
                              type="dashed"
                              onClick={() => removeQuestion(questionField.name)}
                              icon={<MinusCircleOutlined />}
                              style={{
                                width: "20%",
                                display: "flex",
                                marginBottom: "50px",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                alignContent: "flex-end",
                                textAlign: "center",
                              }}
                            >
                              Remove Question
                            </Button>
                          </Box>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </Card>
            <Box my={5} mx={5}>
              {/* <Row gutter={[13][13]} style={{ gap: "10px" }}>
                <Col span={11}>
                  <Audience
                    handleFirstSelectChange={handleFirstSelectChange}
                    AudienceOptions={audience_items}
                  />
                </Col>
              </Row>
              <Row gutter={[13][13]} style={{ gap: "10px" }}>
                {showUnitSelect && (
                  <Col span={11}>
                    <Unit UnitOptions={unit_items} />
                  </Col>
                )}
                {showDepartmentSelect && (
                  <Col span={11}>
                    <Department DepartmentOption={department_lists} />
                  </Col>
                )}
              </Row>
              <Row gutter={[13][13]} style={{ gap: "10px" }}>
                {showDesignationSelect && (
                  <Col span={11}>
                    <Designation DesignationOption={designation_items} />
                  </Col>
                )}
                {showEmployeeSelect && (
                  <Col span={11}>
                    <Employee EmployeeOptions={get_all_employee_list} />
                  </Col>
                )}
              </Row> */}
              <CustomSelect
                handleFirstSelectChange={handleFirstSelectChange}
                AudienceOptions={audience_items}
                showUnitSelect={showUnitSelect}
                showDepartmentSelect={showDepartmentSelect}
                showDesignationSelect={showDesignationSelect}
                showEmployeeSelect={showEmployeeSelect}
                unit_items={unit_items}
                department_lists={department_lists}
                designation_items={designation_items}
                get_all_employee_list={get_all_employee_list}
                handleUnitChange={handleUnitChange}
                handleDepartmentChange={handleDepartmentChange}
                handleDesignationChange={handleDesignationChange}
                handleEmployeeChange={handleEmployeeChange}
              />
              {/* <SurveyAllFormElements
                departmentItem={department_lists}
                unitItem={unit_items}
                employeeItem={get_all_employee_list}
                designationItem={designation_items}
                audienceItem={audience_items}
                onChange={handleChange}
              /> */}
            </Box>
            <HStack justifyContent={"flex-end"} space={3}>
              <Space>
                <Form.Item>
                  <Button onClick={handleGoBack}>Back</Button>
                </Form.Item>
              </Space>
              <Form.Item>
                <Button
                  loading={create_survey_loading}
                  type="primary"
                  htmlType="submit"
                >
                  Send
                </Button>
              </Form.Item>
            </HStack>
          </Form>
        </Box>
      </Card>
    </Box>
  );
};

export default SurveyElementDetails;
