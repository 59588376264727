import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";

import Entypo from "react-native-vector-icons/dist/Entypo";
import { Button } from "antd";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  admin_login_query,
  update_user_password,
} from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { retrieveItem, storeItem } from "@helpers/functions";
import { encryptData } from "@helpers/crypto";
import colors from "@helpers/colors";

const ChangePassword = (props) => {
  const { handleModalClose } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [showPass, setShowPass] = React.useState(false);
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [is_valid_password, set_is_valid_password] = useState(false);
  const [password_strength_color, set_password_strength_color] = useState("");
  const [password_strength, set_password_strength] = useState("");

  const {
    status: change_password_status,
    loading: change_password_loading,
    error: change_password_error,
  } = useDynamicSelector("change_password");

  const handlePasswordChange = (text) => {
    setNewPassword(text);
    getPasswordStrength(text);
  };
  const getPasswordStrength = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[^A-Za-z0-9]/.test(password);

    if (password.length < minLength) {
      set_password_strength_color(colors.darkOverlayColor2);
      set_password_strength("Password is too short");
    } else if (hasUppercase && hasLowercase && hasNumber && hasSpecialChar) {
      set_password_strength_color(colors.green2);
      set_is_valid_password(true);
      set_password_strength("Strong password");
    } else if (
      (hasUppercase || hasLowercase) &&
      (hasNumber || hasSpecialChar)
    ) {
      set_password_strength_color(colors.yellow);
      set_password_strength("Moderate password");
    } else {
      set_password_strength_color(colors.darkRed);
      set_password_strength("Weak password");
    }
  };
  const changePassword = () => {
    let _values = encryptData({
      old_password: current_password,
      new_password: new_password,
    });
    let key = [{ key: "change_password", loading: true }];
    let query = update_user_password;
    let variables = {
      custom: { data: _values },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (current_password.length && new_password.length && is_valid_password) {
        changePassword();
      } else if (!current_password.length) {
        toast.error("Please enter current password");
      } else if (!new_password.length) {
        toast.error("Please enter new password");
      }
    }
  };

  useEffect(() => {
    if (change_password_status === "success") {
      toast.success(t("Password Changed"));
      handleModalClose();
      dispatch(dynamicClear("change_password"));
    } else if (change_password_error) {
      toast.error(change_password_error?.message);
      handleModalClose();

      dispatch(dynamicClear("change_password"));
    }
  }, [change_password_status, change_password_error]);

  return (
    <Box>
      <VStack space="3">
        <Box mx={5}>
          <VStack>
            <Text pt={2} color={"#64748B"} fontWeight={"600"} fontSize={15}>
              {t("current_password")}
            </Text>

            <Input
              placeholder="Current Password"
              onChangeText={(text) => setCurrentPassword(text)}
              value={current_password}
              type={showCurrentPass ? "" : "password"}
              InputRightElement={
                <IconButton
                  variant="unstyled"
                  icon={showCurrentPass ? <AiOutlineEye /> : <AiFillEye />}
                  onPress={() => {
                    {
                      !showCurrentPass
                        ? setShowCurrentPass(true)
                        : setShowCurrentPass(false);
                    }
                  }}
                />
              }
            />

            <Text pt={2} color={"#64748B"} fontWeight={"600"} fontSize={15}>
              {t("new_password")}
            </Text>

            <Input
              w={{
                base: "100%",
              }}
              type={showPass ? "" : "password"}
              placeholder="Password"
              onChangeText={handlePasswordChange}
              value={new_password}
              InputRightElement={
                <IconButton
                  variant="unstyled"
                  icon={showPass ? <AiOutlineEye /> : <AiFillEye />}
                  onPress={() => {
                    {
                      !showPass ? setShowPass(true) : setShowPass(false);
                    }
                  }}
                />
              }
              onKeyPress={handleKeyDown}
            />
            {new_password && (
              <Text
                pt={2}
                px={2}
                color={password_strength_color}
                fontWeight={"600"}
                fontSize={12}
              >
                {password_strength}
              </Text>
            )}
          </VStack>
        </Box>
        <Box borderRadius={5} mx={5} marginTop={5}>
          <Button
            loading={change_password_loading}
            type="primary"
            onClick={() => {
              if (is_valid_password) {
                changePassword();
              } else {
                toast.error(
                  "Password should contain 8+ characters, mixed case, numbers and symbols."
                );
              }
            }}
          >
            {t("change_password")}
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};
export default ChangePassword;
