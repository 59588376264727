import DashboardDetails from "@views/components/user/dashboard/dashboard_details";
import { Box } from "native-base";
import React from "react";

const Dashboard = () => {
  return (
    <Box flex={1}>
      <DashboardDetails />
    </Box>
  );
};
export default Dashboard;
