import React from "react";
import { Box, Center, HStack, Image, Menu, Pressable, Text } from "native-base";
import Avatar from "react-avatar";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { clearLocalStorage, navigate, retrieveItem } from "@helpers/functions";
import { dynamicClear } from "@services/redux";
import { useDispatch } from "react-redux";
import HeaderBreadcrumb from "./header_breadcrumb";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

const RedirectorHeaderBar = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = retrieveItem("role");
  const handleLogout = () => {
    clearLocalStorage();
    dispatch(dynamicClear("loginUser"));
    dispatch(dynamicClear("institution_item"));
    dispatch(dynamicClear("data"));

    navigate(ROUTES.ADMIN_LOGIN);
  };
  const handleChangePassword = () => {};

  const handleAccount = () => {};
  return (
    <Box h={"60px"} w="100%" bg="#212121">
      <HStack
        h="100%"
        w="100%"
        bg="#212121"
        space="2"
        justifyContent={"space-between"}
      >
        <Center>
          {/* <HeaderBreadcrumb /> */}
          <HStack alignItems={"center"}>
            <Image
              source={require(`../../../../assets/icons/avnl_logo_1.png`)}
              width={"60px"}
              height={"60px"}
            />
            <Text
              color={"#fff"}
              fontFamily={"project_header"}
              fontSize={18}
              bold
              mx={2}
            >
              {t("avnl_header")}
            </Text>
          </HStack>
        </Center>

        <Box my={"auto"}>
          <HStack>
            <Box right="10px" ml="6">
              <Button
                style={{
                  borderRadius: 5,
                  backgroundColor: "#fff",
                }}
                // type="primary"
                onClick={() => {
                  navigate("/login");
                }}
              >
                {t("login")}
              </Button>
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default RedirectorHeaderBar;
