import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import {
  dynamicRequest,
  useDynamicSelector,
  getHoCeoList,
  get_senior_accountant_list,
  create_senior_accountant,
  dynamicClear,
  updateSeniorAccountant,
  create_institution,
  get_institution_list,
  update_institution,
  get_zone_list,
  get_district_list,
  delete_institution,
  get_event_list_query,
  employee_list,
  get_all_units,
  department_list,
  get_all_designation,
  create_event_mutation,
} from "@services/redux";
import { useDispatch } from "react-redux";
import Form from "@views/components/system/form";
import { toast } from "react-hot-toast";
import moment from "moment";
import { Button, Input, Modal, Pagination, Table, message } from "antd";
import { BsSend } from "react-icons/bs";
import EventDetails from "./event_details";
import { ROUTES } from "@views/routes/my_routes";
import { navigate, navigateBack } from "@helpers/functions";

const EventList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [is_open, setIsOpen] = useState(false);
  const [delete_id, setDeleteId] = useState("");
  const [incharge_id, setInchargeId] = useState("");
  const [get_one_id, setGetOneId] = useState("");
  const [header_type, setHeaderType] = useState("");
  const [initial_data, setInitialData] = useState({});
  const [event_data, setEventData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [get_search_string, setSearchString] = useState("");

  const { items: unit_items } = useDynamicSelector("get_unit_list");
  const { items: department_lists } = useDynamicSelector("get_department_list");
  const { items: get_all_employee_list } =
    useDynamicSelector("get_employee_list");
  const { items: designation_items } = useDynamicSelector(
    "get_designation_list"
  );
  const {
    items: event_items,
    pagination: event_pagination,
    loading: event_items_loading,
  } = useDynamicSelector("get_event_list");

  const { status: create_event_status, error: create_event_error } =
    useDynamicSelector("create_event");

  const audience_items = [
    {
      id: "unit",
      name: "Unit",
    },
    {
      id: "designation",
      name: "Designation",
    },
    {
      id: "department",
      name: "Department",
    },
    {
      id: "employee",
      name: "Employee",
    },
  ];

  // const getAllEmployee = (search_string) => {
  //   let key = [{ key: "get_employee_list", loading: true }];
  //   let query = employee_list;
  //   let variable = { search_string: search_string };
  //   dispatch(dynamicRequest(key, query, variable));
  // };

  // const getAllUnits = () => {
  //   let key = [{ key: "get_unit_list", loading: true }];
  //   let query = get_all_units;
  //   let variables = {};
  //   dispatch(dynamicRequest(key, query, variables));
  // };
  // const getAllDepartment = () => {
  //   let key = [{ key: "get_department_list", loading: true }];
  //   let query = department_list;
  //   let variable = {};
  //   dispatch(dynamicRequest(key, query, variable));
  // };

  // const getAllDesignation = () => {
  //   let key = [{ key: "get_designation_list", loading: true }];
  //   let query = get_all_designation;
  //   let variables = {};
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  const getAllEvent = () => {
    let key = [{ key: "get_event_list", loading: true }];
    let query = get_event_list_query;
    let variable = {
      filter: { search_string: get_search_string },
      pagination: {
        page_number: current,
        page_limit: current_limit,
      },
      sort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const fetchData = (page_number, page_limit) => {
    let key = [{ key: "get_event_list", loading: true }];
    let query = get_event_list_query;
    let variable = {
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
      sort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const onAdd = () => {
    setHeaderType("add");
    navigate(ROUTES.EVENTS_UPLOAD_DETAILS);
    setInitialData({});
    setGetOneId("");
  };

  // const onEdit = (id) => {
  //   setHeaderType("edit");
  //   navigate(`${ROUTES.EVENTS_UPLOAD_DETAILS}/${id}`);
  //   setGetOneId(id);
  // };

  const onView = (item) => {
    handleModalOpen(item);
    // showModal()
  };

  // const onDelete = (id) => {
  //   console.log("onDelete", id);
  // };

  const onChange = (
    page_number,
    page_limit,
    sort_key,
    sort_direction,
    search_string,
    filter
  ) => {
    console.log(
      "onPageChange",
      page_number,
      page_limit,
      sort_key,
      sort_direction,
      search_string,
      filter
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (item) => {
    setIsModalOpen(true);
    setEventData(item);
  };

  const handleDeleteModalOpen = (id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(id);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const onFormCancel = () => {
    setIsOpen(false);
    setGetOneId("");
    setInitialData({});
  };

  const onFormChange = (values) => {
    console.log("onFormChange", values);
  };

  const onFormSubmit = (values) => {
    console.log("onFormSubmit", values);
  };

  const columns = [
    {
      title: t("event_title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "20%",
      render: (record, rowIndex) => {
        return (
          <Pressable
            onPress={() => {
              onView(record);
            }}
          >
            <AiOutlineEye size={20} color="#000000" />
          </Pressable>
        );
      },
    },
  ];

  const form_fields = [
    {
      field: "event_title",
      label: t("event_title"),
      type: "text",
      rules: [{ required: true, message: t("error:event_title_is_required") }],
    },

    {
      field: "description",
      label: t("description"),
      type: "text",
      rules: [
        { required: true, message: t("error:event_description_is_required") },
      ],
      isMulti: true,
    },

    {
      field: "audience",
      label: t("audience"),
      type: "select",
      // rules: [
      //   { required: true, message: t("error:announcement_unit_is_required") },
      // ],
      options: audience_items,
    },
    {
      field: "units",
      label: t("unit"),
      type: "select",
      // rules: [
      //   { required: true, message: t("error:announcement_unit_is_required") },
      // ],
      options: unit_items,
      isMulti: true,
    },
    {
      field: "departments",
      label: t("department"),
      type: "select",
      // rules: [
      //   {
      //     required: true,
      //     message: t("error:announcement_department_is_required"),
      //   },
      // ],
      options: department_lists,
      isMulti: true,
    },
    {
      field: "designations",
      label: t("designation"),
      type: "select",
      // rules: [
      //   {
      //     required: true,
      //     message: t("error:announcement_designation_is_required"),
      //   },
      // ],
      options: designation_items,
      isMulti: true,
    },
    {
      field: "employees",
      label: t("employee"),
      type: "select",
      // rules: [
      //   {
      //     required: true,
      //     message: t("error:announcement_employee_is_required"),
      //   },
      // ],
      options: get_all_employee_list,
      isMulti: true,
    },
    // {
    //   field: "event_document",
    //   label: t("event_document"),
    //   type: "file",
    // },
  ];

  useEffect(() => {
    if (create_event_status === "success") {
      toast.success(t("Event Sent"));
      dispatch(dynamicClear("create_event"));
      onFormCancel();
      getAllEvent();
    } else if (create_event_error) {
      toast.error(create_event_error?.message);
      dispatch(dynamicClear("create_event"));
    }
  }, [create_event_status, create_event_error]);
  useEffect(() => {
    if (get_one_id) {
      let getOneItem = event_items?.find((x) => {
        return x.id === get_one_id;
      });
      setInitialData({ ...getOneItem, event_document: [{}] });
      setIsOpen(true);
    }
  }, [get_one_id]);

  useEffect(() => {
    getAllEvent({ search_string: get_search_string });
  }, [get_search_string]);

  useEffect(() => {}, [event_items]);

  const handleSearch = (value) => {
    setSearchString(value);
  };

  return (
    <Box flex={1}>
      <Box padding={5}>
        <Box py="20px">
          <Text fontSize={20} bold>
            {t("events")}
          </Text>
        </Box>
        <HStack mb={5} justifyContent={"flex-end"} mx={5} space={3}>
          <Box width={"200px"}>
            <Input.Search label="Search" allowClear onSearch={handleSearch} />
          </Box>
          <Box>
            <Button type="primary" onClick={onAdd}>
              {t("add_event")}
            </Button>
          </Box>
        </HStack>
        <Table
          dataSource={event_items}
          columns={columns}
          loading={event_items_loading}
          onChange={onChange}
          pagination={false}
        />
        <Box alignItems={"flex-end"} my={5}>
          <HStack alignItems={"center"}>
            <Text mx={2} fontSize="md">
              {`Total ${
                event_pagination?.total_count
                  ? event_pagination?.total_count
                  : 0
              } Items`}
            </Text>
            <Pagination
              showSizeChanger={true}
              hideOnSinglePage={false}
              onChange={fetchData}
              total={event_pagination?.total_count}
              pageSizeOptions={[5, 10, 15, 20, 50]}
            />
          </HStack>
        </Box>
      </Box>
      <Form
        title={header_type === "edit" ? t("edit_event") : t("add_event")}
        submitLabel="Save"
        isSubmitLoading={false}
        isOpen={is_open}
        width={500}
        initialData={initial_data}
        fields={form_fields}
        onCancel={onFormCancel}
        onSubmit={onFormSubmit}
        onChange={onFormChange}
      />
      <Modal
        title={event_data?.title}
        open={is_modal_open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleModalClose();
              }}
            >
              {t("close")}
            </Button>
            {/* <Button type="primary" loading={false} onClick={() => {}}>
              {t("send")}
            </Button> */}
          </HStack>
        }
        width={500}
        style={{
          borderRadius: 50,
        }}
      >
        <EventDetails
          handleModalClose={handleModalClose}
          event_item={event_data}
        />
      </Modal>

      <Modal
        width={450}
        open={isDeleteModalOpen}
        onCancel={handleDeleteModalClose}
        onOk={() => {}}
        title={t("confirmation")}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleDeleteModalClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button type="primary" loading={false} onClick={() => {}}>
              {t("delete")}
            </Button>
          </HStack>
        }
      >
        <Text>{t("are_you_sure_you_want_to_delete_this_events?")}</Text>
      </Modal>
    </Box>
  );
};
export default EventList;
