import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useRef, useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";
import { IoReloadOutline } from "react-icons/io5";

import Entypo from "react-native-vector-icons/dist/Entypo";
import { Button } from "antd";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  admin_login_query,
  get_captcha_query,
  get_request_seed,
} from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { retrieveItem, storeItem } from "@helpers/functions";
import { decryptData, DynamicEncryptData, encryptData } from "@helpers/crypto";
import { MdOutlineRefresh } from "react-icons/md";

const AdminLoginForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [user_name, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [input_captcha, setCaptcha] = useState("");
  const [seed_request, set_seed_request] = useState({});

  const [show_pass, setShowPass] = React.useState(false);
  const canvasRef = useRef();

  const {
    captcha,
    error: request_captcha_error,
    loading: request_captcha_loading,
  } = useDynamicSelector("request_captcha");
  const {
    data: get_seed_response,
  } = useDynamicSelector("requestSeed");
  console.log("get_seed_response", get_seed_response, seed_request);
  const {
    error,
    session_id,
    user_id,
    system_user,
    status,
    page_accesses,
    page_component_accesses,
    loading: loginLoading,
    data: login_data,
  } = useDynamicSelector("login");

  useEffect(() => {
    if (request_captcha_error) {
      toast.error(request_captcha_error?.message);
      dispatch(dynamicClear("request_captcha"));
    }
  }, [request_captcha_error]);

  useEffect(() => {
    if (captcha) {
      createCaptcha();
    }
  }, [captcha]);

  useEffect(() => {
    if (login_data) {
      let decrypted_login_data = decryptData(login_data);
      storeItem("session_id", decrypted_login_data?.session_id);
      storeItem("user_id", decrypted_login_data?.user_id);
      storeItem("page_accesses", decrypted_login_data?.page_accesses);
      storeItem(
        "page_component_accesses",
        decrypted_login_data?.page_component_accesses
      );
      storeItem("role", decrypted_login_data?.system_user?.roles?.[0]?.name);
      storeItem("login_username", decrypted_login_data?.system_user?.username);

      const temp = retrieveItem("page_accesses");

      if (decrypted_login_data?.system_user?.roles) {
        if (decrypted_login_data?.system_user?.roles?.[0]?.name === "Admin") {
          navigate(ROUTES.ANNOUNCEMENTS);
        } else if (
          decrypted_login_data?.system_user?.roles?.[0]?.name === "Unit Admin"
        ) {
          navigate(ROUTES.ANNOUNCEMENTS);
        }
      }
    }
  }, [, login_data]);
  useEffect(() => {
    if (error) {
      if (error.message === "invalid_credentials") {
        set_seed_request({})
        handle_captcha()
        toast.error("Invalid Credentials");
        dispatch(dynamicClear("login"))
      } else {
        toast.error(error.message);
        set_seed_request({})
        handle_captcha()
        dispatch(dynamicClear("login"))
      }
    }
  }, [error])

  useEffect(() => {
    get_request_seed_value()
  }, [])

  useEffect(() => {
    if (get_seed_response) {
      let value = decryptData(get_seed_response)
      set_seed_request(value)
      dispatch(dynamicClear("requestSeed"))
    }
  }, [get_seed_response])

  const createCaptcha = () => {
    let decryptCaptcha = decryptData(captcha);
    var canvas = canvasRef.current;
    var context = canvas.getContext("2d");
    context.clearRect(0, 0, 100, 100);
    context.font = "18px Georgia";
    context.fillText(decryptCaptcha, 0, 30);
  };

  const handleKeyDown = (event) => {
    const allowedPattern = /^[^<>]+$/;

    if (event.key === "Enter") {
      if (user_name.length && password.length && input_captcha, allowedPattern.test(user_name) && allowedPattern.test(password) && allowedPattern.test(input_captcha)) {
        Login();
      } else if (!user_name.length) {
        toast.error("Please enter valid username");
      } else if (!password.length) {
        toast.error("Please enter valid password");
      } else if (!input_captcha) {
        toast.error("Please enter captcha");
      } else {
        toast.error("Invalid characters entered.");
      }
    }
  };
  const Login = () => {
    // let username = encryptData({
    //   username: user_name,
    //   password: password,
    // });
    let encrypt_username = DynamicEncryptData(user_name, seed_request?.key)
    let encrypt_password = DynamicEncryptData(password, seed_request?.key)
    let user_login_key = [{ key: "login", loading: true }];
    let user_login_query = admin_login_query;
    let user_login_variables = {
      custom: {
        id: seed_request?.id,
        username: encrypt_username,
        password: encrypt_password,
        captcha: input_captcha,
      },
    };
    dispatch(
      dynamicRequest(user_login_key, user_login_query, user_login_variables)
    );
  };

  const get_captcha_value = () => {
    let keys = [{ key: "request_captcha", loading: true }];
    let query = get_captcha_query;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables));
  };
  const get_request_seed_value = () => {
    let keys = [{ key: "requestSeed", loading: true }];
    let query = get_request_seed;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables));
  };
  const handle_captcha = () => {
    get_request_seed_value()
    get_captcha_value()
  }


  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "coolGray.100",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
      >
        <VStack space="7">
          <Hidden till="md">
            <HStack justifyContent={"center"} alignItems="center">
              {/* <Image
                cursor={"pointer"}
                px={2}
                py={2}
                width={40}
                height={40}
                alt="logo"
                resizeMode={"contain"}
                source={logo}
                borderRadius={20}
              /> */}
              <Text
                // backgroundColor={"#231f20"}
                color={"#024724"}
                bold
                fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
                textAlign={"center"}
                fontWeight="normal"
              >
                {t("avnl")}
              </Text>
            </HStack>
          </Hidden>
          <VStack>
            <VStack space="3">
              <Box mx={5}>
                {/* <Box alignItems={"center"}> */}
                <VStack>
                  <Text
                    pt={2}
                    color={"#64748B"}
                    fontWeight={"600"}
                    fontSize={15}
                  >
                    {t("user_name")}
                  </Text>

                  <Input
                    // variant="rounded"
                    placeholder="Username"
                    onChangeText={(text) => setUserName(text)}
                    value={user_name}
                    maxLength={50}
                    onKeyPress={handleKeyDown}
                  />

                  <Text
                    pt={2}
                    color={"#64748B"}
                    fontWeight={"600"}
                    fontSize={15}
                  >
                    {t("password")}
                  </Text>

                  <Input
                    w={{
                      base: "100%",
                    }}
                    type={show_pass ? "" : "password"}
                    // variant="rounded"
                    placeholder="Password"
                    onChangeText={(text) => setPassword(text)}
                    maxLength={50}
                    value={password}
                    InputRightElement={
                      <IconButton
                        variant="unstyled"
                        icon={
                          show_pass ? <AiOutlineEye /> : <AiFillEye />
                          // <Icon
                          //   size="4"
                          //   color="coolGray.400"
                          //   as={Entypo}
                          //   name={show_pass ? "eye-with-line" : "eye"}
                          // />
                        }
                        onPress={() => {
                          {
                            !show_pass ? setShowPass(true) : setShowPass(false);
                          }
                        }}
                      />
                    }
                    onKeyPress={handleKeyDown}
                  />
                  <HStack mt={5} space={"50px"}>
                    <Box>
                      <canvas
                        className="MobileCanvas"
                        width="150"
                        height="40"
                        ref={canvasRef}
                        style={{ marginLeft: "50px", scale: "1.3" }}
                      />
                    </Box>
                    <Box position={"absolute"} marginLeft={120} marginTop={4}>
                      <MdOutlineRefresh
                        onClick={handle_captcha}
                        size={20}
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                    <Input
                      placeholder="Captcha"
                      onChangeText={(text) => setCaptcha(text)}
                      value={input_captcha}
                      maxLength={6}
                      onKeyPress={handleKeyDown}
                    />
                  </HStack>
                </VStack>
                {/* </Box> */}
              </Box>
              <Box borderRadius={5} mx={5} marginTop={5}>
                <Button
                  loading={loginLoading}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    if (user_name.length && password.length && input_captcha) {
                      Login();
                    } else if (!user_name.length) {
                      toast.error("Please enter valid username");
                    } else if (!password.length) {
                      toast.error("Please enter valid password");
                    } else if (!input_captcha) {
                      toast.error("Please enter captcha");
                    }
                  }}
                >
                  SIGN IN
                </Button>
              </Box>
              <Box mx={5} alignItems={"flex-end"}>
                <Pressable
                  onPress={() => {
                    navigate(ROUTES.POLICY);
                  }}
                >
                  <Text underline>{t("privacy_policy")}</Text>
                </Pressable>
              </Box>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </ScrollView>
  );
};

export default AdminLoginForm;
