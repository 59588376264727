import React from "react";
import { Card, Col, Form, Row } from "antd";
import Unit from "../dynamic_fields/announcement_form_elements/unit";
import Department from "../dynamic_fields/announcement_form_elements/department";
import Designation from "../dynamic_fields/announcement_form_elements/designation";
import Employee from "../dynamic_fields/announcement_form_elements/employee";
import Audience from "../dynamic_fields/announcement_form_elements/audience";
import { retrieveItem } from "@helpers/functions";
import { Text } from "native-base";
import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";

const CustomSelect = (props) => {
  const {
    unit_items,
    department_lists,
    designation_items,
    get_all_employee_list,
    showUnitSelect,
    showDepartmentSelect,
    showDesignationSelect,
    showEmployeeSelect,
    handleUnitChange,
    handleDepartmentChange,
    handleDesignationChange,
    handleEmployeeChange,
    handleFirstSelectChange,
    initial_data,
  } = props;

  const current_role = retrieveItem("role");
  const audience_items = [
    {
      id: "unit",
      name: "Unit",
    },
    {
      id: "designation",
      name: "Designation",
    },
    {
      id: "department",
      name: "Department",
    },
    {
      id: "employee",
      name: "Employee",
    },
  ];
  const audience_unit_items = [
    {
      id: "designation",
      name: "Designation",
    },
    {
      id: "department",
      name: "Department",
    },
    {
      id: "employee",
      name: "Employee",
    },
  ];
  return (
    <Card>
      <Text fontSize={20} mb={5}>
        {"Notification"}
      </Text>
      <Row gutter={[13][13]} style={{ gap: "10px" }}>
        <Col span={11}>
          <Audience
            handleFirstSelectChange={handleFirstSelectChange}
            AudienceOptions={
              current_role === "Unit Admin"
                ? audience_unit_items
                : audience_items
            }
          />
        </Col>
      </Row>

      <Row gutter={[13][13]} style={{ gap: "10px" }}>
        {current_role !== "Unit Admin" && (
          <>
            {showUnitSelect &&
                <Col span={11}>
                  <Unit UnitOptions={unit_items} onChange={handleUnitChange} />
                </Col>
              }
          </>
        )}

        {showDepartmentSelect && (
          <Col span={11}>
            <Department
              DepartmentOption={department_lists}
              onChange={handleDepartmentChange}
            />
          </Col>
        )}
      </Row>
      <Row gutter={[13][13]} style={{ gap: "10px" }}>
        {showDesignationSelect && (
          <Col span={11}>
            <Designation
              DesignationOption={designation_items}
              onChange={handleDesignationChange}
            />
          </Col>
        )}
        {showEmployeeSelect && (
          <Col span={11}>
            <Employee
              EmployeeOptions={get_all_employee_list}
              onChange={handleEmployeeChange}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};
export default CustomSelect;
