import { gql } from "@apollo/client";

export const admin_login_query = gql`
  query login($custom: admin_input) {
    login: admin_login(custom: $custom) {
      data
      error {
        status_code
        message
      }
      status
      page_accesses
    }
  }
`;
export const get_captcha_query = gql`
  query {
    request_captcha {
      captcha
      error {
        status_code
        message
      }
    }
  }
`;

export const get_request_seed = gql`
  query requestSeed {
    requestSeed {
      data
    }
  }
`;
