import { useCheckLogin } from "@helpers/hooks";
import { navigate } from "@helpers/navigator";
import { Box, Center } from "native-base";
import BackgroundLogo from "@assets/icons/armed.JPG";

import React, { useEffect, useState } from "react";
import AdminLogin from "@views/components/user/auth/admin_login";
import HeaderBar from "@views/components/user/header_bar/header_bar";
import RedirectorHeaderBar from "@views/components/user/header_bar/redirector_header_bar";
const Redirector = (props) => {
  const is_logged_in = useCheckLogin();
  useEffect(() => {
    if (is_logged_in === true) {
      navigate("/login");
    } else {
      navigate("/");
    }
  }, [is_logged_in]);

  return (
    <Box flex={1}>
      <Center
        height={200}
        my="auto"
        _dark={{
          bg: "#024724",
        }}
        _light={{
          bg: "#024724",
        }}
        bgImage={`url(${BackgroundLogo})`}
        style={{
          backgroundSize: "cover",
        }}
        flex="1"
      >
        {/* <AdminLogin /> */}
      </Center>
      <Box width={"100%"} position={"absolute"} top={0}>
        <RedirectorHeaderBar />
      </Box>
    </Box>
  );
};
export default Redirector;
