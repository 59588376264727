import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import React from "react";

const AnnouncementTitle = () => {
  return (
    <AntdDynamicForm.TextBox
      label="Announcement title"
      field={"announcement_title"}
      rules={[
        {
          required: true,
          message: "Title is required!",
        },
      ]}
    />
  );
};
export default AnnouncementTitle;
