import Redirector from "@views/pages/redirector";
import AdminLoginPage from "@views/pages/admin_login";
import UserLayout from "@views/layouts/user_layout";
import Logout from "@views/pages/logout";
// import InstitutionView from "@views/pages/institution_view";
import AccessRights from "@views/components/system/access_rights/access_rights";
import Dashboard from "@views/pages/dashboard";
import Announcements from "@views/pages/announcements";
import Circulars from "@views/pages/circulars";
import Events from "@views/pages/events";
import Survey from "@views/pages/survey";
import Units from "@views/pages/units";
import Employee from "@views/pages/employee";
import Designations from "@views/pages/designations";
import Department from "@views/pages/department";
import EventsUploadDetails from "@views/components/user/events/events_upload_details";
import SurveyElementDetails from "@views/components/user/survey/survey_element_details";
// import CompletedSurvey from "@views/components/user/publishsurvey/completed_survey";
import CompletedSurvey from "@views/pages/completed_survey";
import PublishSurveyDetails from "@views/components/user/publishsurvey/publishsurvey_details";
import Policy from "@views/pages/privacy_policy";
import EditAnnouncement from "@views/components/user/announcements/edit_announcement";
import EditCircular from "@views/components/user/circulars/edit_circular";

export const ROUTES = {
  REDIRECTOR: "/",
  REGISTER: "/register",
  ADMIN_LOGIN: "/login",
  POLICY: "/privacy-policy",
  FORGOT_PASSWORD: "/forgot-password",
  LOGOUT: "/logout",
  ACCESS_RIGHTS: "/access-rights",
  DASHBOARD: "/dashboard",
  ANNOUNCEMENTS: "/announcements",
  CIRCULARS: "/circulars",
  EVENTS: "/events",
  SURVEY: "/survey",
  PUBLISH_SURVEY: "/publish-survey",
  UNITS: "/units",
  DESIGNATION: "/designation",
  EMPLOYEE: "/employee",
  DEPARTMENT: "/department",
  EVENTS_UPLOAD_DETAILS: "/events-details",
  SURVEY_ELEMENTS: "/survey-elements",
  // COMPLETED_SURVEY: "/completed-survey",
  PUBLISH_SURVEY_VIEW: "PublishSurveyView",
  EDIT_ANNOUNCEMENT: "/announcement-details",
  EDIT_CIRCULAR: "/circular-details",
};

const myRoutes = [
  {
    path: ROUTES.REDIRECTOR,
    exact: true,
    key: "redirector",
    component: Redirector,
    authenticate: false,
  },
  {
    path: ROUTES.ACCESS_RIGHTS,
    key: "Access Rights",
    authenticate: false,
    component: AccessRights,
  },
  {
    path: ROUTES.LOGOUT,
    key: "logout",
    component: Logout,
    authenticate: false,
  },

  {
    path: ROUTES.ADMIN_LOGIN,
    key: "admin_login",
    authenticate: false,
    component: AdminLoginPage,
  },
  {
    path: ROUTES.POLICY,
    key: "policy",
    authenticate: false,
    component: Policy,
  },

  {
    key: "Admin",
    component: UserLayout,
    authenticate: false,
    children: [
      {
        path: ROUTES.DASHBOARD,
        exact: true,
        key: "Dashboard",
        authenticate: true,
        component: Dashboard,
      },
      {
        path: ROUTES.ANNOUNCEMENTS,
        exact: true,
        key: "Announcements",
        authenticate: true,
        component: Announcements,
      },
      {
        path: ROUTES.CIRCULARS,
        exact: true,
        key: "Circulars",
        authenticate: true,
        component: Circulars,
      },
      {
        path: ROUTES.EVENTS,
        exact: true,
        key: "Events",
        authenticate: true,
        component: Events,
      },

      {
        path: ROUTES.SURVEY,
        exact: true,
        key: "Survey",
        authenticate: true,
        component: Survey,
      },
      {
        path: `${ROUTES.PUBLISH_SURVEY}/:id?`,
        exact: true,
        key: "Publish Survey",
        authenticate: true,
        component: PublishSurveyDetails,
      },
      {
        path: ROUTES.UNITS,
        exact: true,
        key: "Units",
        authenticate: true,
        component: Units,
      },
      {
        path: ROUTES.DEPARTMENT,
        exact: true,
        key: "Departments",
        authenticate: true,
        component: Department,
      },
      {
        path: ROUTES.DESIGNATION,
        exact: true,
        key: "Designations",
        authenticate: true,
        component: Designations,
      },
      {
        path: ROUTES.EMPLOYEE,
        exact: true,
        key: "Employees",
        authenticate: true,
        component: Employee,
      },
      {
        path: `${ROUTES.EVENTS_UPLOAD_DETAILS}/:id?`,
        exact: true,
        key: "Event upload details",
        authenticate: true,
        component: EventsUploadDetails,
      },
      {
        path: `${ROUTES.SURVEY_ELEMENTS}/:id?`,
        exact: true,
        key: "Survey elements",
        authenticate: true,
        component: SurveyElementDetails,
      },
      {
        path: `${ROUTES.EDIT_ANNOUNCEMENT}/:id?`,
        exact: true,
        key: "Edit announcement",
        authenticate: true,
        component: EditAnnouncement,
      },
      {
        path: ROUTES.EDIT_CIRCULAR,
        exact: true,
        key: "Edit circular",
        authenticate: true,
        component: EditCircular,
      },
    ],
  },
];

export default myRoutes;
