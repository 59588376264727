import React, { useState } from "react";
import { Box, Center, HStack, Image, Menu, Pressable, Text } from "native-base";
import Avatar from "react-avatar";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { clearLocalStorage, navigate, retrieveItem } from "@helpers/functions";
import { dynamicClear } from "@services/redux";
import { useDispatch } from "react-redux";
import HeaderBreadcrumb from "./header_breadcrumb";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import ChangePassword from "../change_password/change_password";

const HeaderBar = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const role = retrieveItem("role");
  const login_username = retrieveItem("login_username");
  const [is_modal_open, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleLogout = () => {
    clearLocalStorage();
    dispatch(dynamicClear("login"));
    dispatch(dynamicClear("data"));

    navigate(ROUTES.ADMIN_LOGIN);
  };

  const handleChangePassword = () => {};

  const handleAccount = () => {};
  return (
    <Box
      // zIndex={3}
      borderColor={"#111"}
      shadow={"2"}
      h={"80px"}
      w="100%"
      bg="#ffffff"
    >
      <HStack
        h="100%"
        w="100%"
        bg="#fff"
        space="2"
        shadow={"2"}
        justifyContent={"space-between"}
      >
        <Box width={"240px"} backgroundColor={"#231f20"}>
          <Center>
            {/* <HeaderBreadcrumb /> */}
            <HStack alignItems={"center"}>
              <Image
                source={require(`../../../../assets/icons/avnl_logo_1.png`)}
                width={"60px"}
                height={"60px"}
              />
              <Text
                color={"#fff"}
                fontFamily={"project_header"}
                fontSize={18}
                bold
                mx={2}
              >
                {t("avnl_header")}
              </Text>
            </HStack>
          </Center>
        </Box>

        <Box my={"auto"}>
          <HStack>
            <Box right="10px" ml="6">
              <Menu
                w="160"
                position="absolute"
                right="0px"
                top="20px"
                style={{
                  border: "1px solid #d7d7d7",
                  boxShadow: "-2px 2px #7a7a7a42",
                }}
                shouldOverlapWithTrigger={true}
                placement={"left bottom"}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      alignSelf="center"
                      alignItems="center"
                      variant="solid"
                      {...triggerProps}
                    >
                      <HStack>
                        {login_username ? (
                          <Text bold fontSize="md" fontWeight="">
                            {`Welcome, ${login_username} ( ${role} )`}
                          </Text>
                        ) : (
                          <Text bold fontSize="md" fontWeight="">
                            Welcome, {role}
                          </Text>
                        )}
                        <Box right="10px" ml="6">
                          <Avatar
                            round
                            size="25"
                            src={"https://via.placeholder.com/150"}
                          />
                        </Box>
                      </HStack>
                    </Pressable>
                  );
                }}
              >
                {/* <Menu.Item onPress={handleAccount}>Account</Menu.Item> */}

                <Menu.Item
                  onPress={() => {
                    handleModalOpen();
                  }}
                >
                  Change Password
                </Menu.Item>

                <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
              </Menu>
            </Box>
          </HStack>
        </Box>
      </HStack>

      <Modal
        title={t("change_password")}
        open={is_modal_open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={null}
        width={500}
        destroyOnClose={true}
      >
        <ChangePassword handleModalClose={handleModalClose} />
      </Modal>
    </Box>
  );
};

export default HeaderBar;
