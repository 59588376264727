import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
  getSurveyList,
  get_all_designation,
  department_list,
  get_all_units,
  employee_list,
  create_survey_mutation,
} from "@services/redux";
import { useDispatch } from "react-redux";
import Form from "@views/components/system/form";
import { toast } from "react-hot-toast";
import moment from "moment";
import { Button, Input, Modal, Pagination, Table, message } from "antd";
import { BsSend } from "react-icons/bs";
import SurveyDetails from "./survey_details";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { getUUID, navigate } from "@helpers/functions";
import Upload from "antd/lib/upload/Upload";
import { BiUpload } from "react-icons/bi";
import Search from "antd/lib/transfer/search";

const SurveyList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [is_open, setIsOpen] = useState(false);
  const [delete_id, setDeleteId] = useState("");
  const [incharge_id, setInchargeId] = useState("");
  const [get_one_id, setGetOneId] = useState("");
  const [header_type, setHeaderType] = useState("");
  const [initial_data, setInitialData] = useState({});
  const [survey_data, setSurveyData] = useState({});
  const [survey_status, setSurveyStatus] = useState({});
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [get_search_string, setSearchString] = useState("");
  const history = useHistory();

  const { items: unit_items } = useDynamicSelector("get_unit_list");
  const { items: department_lists } = useDynamicSelector("get_department_list");
  const { items: get_all_employee_list } =
    useDynamicSelector("get_employee_list");
  const { items: designation_items } = useDynamicSelector(
    "get_designation_list"
  );
  const {
    items: survey_items,
    pagination: survey_items_pagination,
    loading: survey_items_loading,
  } = useDynamicSelector("get_survey_list");

  const { status: create_survey_status, error: create_survey_error } =
    useDynamicSelector("create_survey");

  useEffect(() => {
    let temp = survey_items?.map((x) => {
      return x?.status;
    });
    setSurveyStatus(temp);
  }, [survey_items]);

  const audience_items = [
    {
      id: "unit",
      name: "Unit",
    },
    {
      id: "designation",
      name: "Designation",
    },
    {
      id: "department",
      name: "Department",
    },
    {
      id: "employee",
      name: "Employee",
    },
  ];
  const getAllEmployee = (search_string) => {
    let key = [{ key: "get_employee_list", loading: true }];
    let query = employee_list;
    let variable = { search_string: search_string };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllUnits = () => {
    let key = [{ key: "get_unit_list", loading: true }];
    let query = get_all_units;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAllDepartment = () => {
    let key = [{ key: "get_department_list", loading: true }];
    let query = department_list;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllDesignation = () => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllSurvey = () => {
    let key = [{ key: "get_survey_list", loading: true }];
    let query = getSurveyList;
    let variable = {
      filter: { search_string: get_search_string },
      pagination: {
        page_number: current,
        page_limit: current_limit,
      },
      sort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const onAdd = () => {
    navigate(ROUTES.SURVEY_ELEMENTS);
    setHeaderType("add");

    setInitialData({});
    setGetOneId("");
  };

  const onPublish = (item) => {
    setHeaderType("edit");
    history.push({ pathname: `${ROUTES.PUBLISH_SURVEY}/${item}` });
    setGetOneId(item);
  };

  const onView = (item) => {
    handleModalOpen(item);
  };

  const onChange = (
    page_number,
    page_limit,
    sort_key,
    sort_direction,
    search_string,
    filter
  ) => {
    console.log(
      "onPageChange",
      page_number,
      page_limit,
      sort_key,
      sort_direction,
      search_string,
      filter
    );
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (item) => {
    setIsModalOpen(true);
    setSurveyData(item);
  };

  const handleDeleteModalOpen = (id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(id);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const onFormCancel = () => {
    setIsOpen(false);
    setGetOneId("");
    setInitialData({});
  };

  const onFormChange = (values) => {
    console.log("onFormChange", values);
  };

  const onFormSubmit = (values) => {
    createSurvey(values);
  };

  const fetchData = (page_number, page_limit) => {
    let key = [{ key: "get_survey_list", loading: true }];
    let query = getSurveyList;
    let variable = {
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
      sort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    };
    dispatch(dynamicRequest(key, query, variable));
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };
  const columns = [
    {
      title: t("survey_name"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "20%",
      render: (record, rowIndex) => {
        return (
          <HStack space={"10px"}>
            <Pressable
              onPress={() => {
                onView(record);
              }}
            >
              <AiOutlineEye size={20} color="#000000" />
            </Pressable>
            {record.status === "published" ? (
              " "
            ) : (
              <Pressable
                onPress={() => {
                  onPublish(record.id);
                }}
              >
                <BiUpload size={20} color="#000000" />
              </Pressable>
            )}
          </HStack>
        );
      },
    },
  ];

  const form_fields = [
    {
      field: "survey_question",
      label: t("survey_question"),
      type: "text_area",
      rules: [
        { required: true, message: t("error:survey_question_is_required") },
      ],
    },

    {
      field: "option",
      label: t("option"),
      type: "text",
      rules: [
        { required: true, message: t("error:survey_option_is_required") },
      ],

      isMulti: true,
    },
    {
      field: "value",
      label: t("value"),
      type: "text",
      rules: [{ required: true, message: t("error:survey_value_is_required") }],

      isMulti: true,
    },
    {
      field: "audience",
      label: t("audience"),
      type: "select",
      // rules: [
      //   { required: true, message: t("error:announcement_unit_is_required") },
      // ],
      options: audience_items,
    },
    {
      field: "units",
      label: t("unit"),
      type: "select",
      // rules: [
      //   { required: true, message: t("error:announcement_unit_is_required") },
      // ],
      options: unit_items,
      isMulti: true,
    },
    {
      field: "departments",
      label: t("department"),
      type: "select",
      // rules: [
      //   {
      //     required: true,
      //     message: t("error:announcement_department_is_required"),
      //   },
      // ],
      options: department_lists,
      isMulti: true,
    },
    {
      field: "designations",
      label: t("designation"),
      type: "select",
      // rules: [
      //   {
      //     required: true,
      //     message: t("error:announcement_designation_is_required"),
      //   },
      // ],
      options: designation_items,
      isMulti: true,
    },
    {
      field: "employees",
      label: t("employee"),
      type: "select",
      // rules: [
      //   {
      //     required: true,
      //     message: t("error:announcement_employee_is_required"),
      //   },
      // ],
      options: get_all_employee_list,
      isMulti: true,
    },
  ];
  useEffect(() => {
    if (create_survey_status === "success") {
      toast.success(t("Survey Sent"));
      dispatch(dynamicClear("create_survey"));
      onFormCancel();
      getAllSurvey();
    } else if (create_survey_error) {
      toast.error(create_survey_error?.message);
      dispatch(dynamicClear("create_survey"));
    }
  }, [create_survey_status, create_survey_error]);

  useEffect(() => {
    if (get_one_id) {
      let getOneItem = survey_items?.find((x) => {
        return x.id === get_one_id;
      });
      setInitialData({ ...getOneItem });
      setIsOpen(true);
    }
  }, [get_one_id]);

  useEffect(() => {
    getAllEmployee();
    getAllDesignation();
    getAllUnits();
    getAllDepartment();
  }, []);

  useEffect(() => {
    getAllSurvey({ search_string: get_search_string });
  }, [get_search_string]);

  const handleSearch = (value) => {
    setSearchString(value);
  };

  return (
    <Box flex={1}>
      <Box padding={5}>
      <Box py="20px">
        <Text fontSize={20} bold>
          {"Surveys"}
        </Text>
      </Box>
      </Box>
      <HStack justifyContent={"flex-end"} mx={5} space={3}>
        {/* <Box>
          <Search
            size="medium"
            placeholder="Search..."
            width="100px"
          />
        </Box> */}
        <Box width={"200px"}>
          <Input.Search label="Search" allowClear onSearch={handleSearch} />
        </Box>
        <Box>
          <Button type="primary" onClick={onAdd}>
            {t("add_survey")}
          </Button>
        </Box>
      </HStack>
      <Box padding={5}>
        <Table
          dataSource={survey_items}
          columns={columns}
          loading={survey_items_loading}
          onChange={onChange}
          pagination={false}
        />
        <Box alignItems={"flex-end"} my={5}>
          <HStack alignItems={"center"}>
            <Text mx={2} fontSize="md">
              {`Total ${
                survey_items_pagination?.total_count
                  ? survey_items_pagination?.total_count
                  : 0
              } Items`}
            </Text>
            <Pagination
              showSizeChanger={true}
              hideOnSinglePage={false}
              onChange={fetchData}
              total={survey_items_pagination?.total_count}
              pageSizeOptions={[5, 10, 15, 20, 50]}
            />
          </HStack>
        </Box>
      </Box>
      <Form
        title={header_type === "edit" ? t("edit_survey") : t("add_survey")}
        submitLabel="Save"
        isSubmitLoading={false}
        isOpen={is_open}
        width={500}
        initialData={initial_data}
        fields={form_fields}
        onCancel={onFormCancel}
        onSubmit={onFormSubmit}
        onChange={onFormChange}
      />
      <Modal
        title={`${survey_data?.title} Questions`}
        open={is_modal_open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleModalClose();
              }}
            >
              {t("close")}
            </Button>
            {/* <Button type="primary" loading={false} onClick={() => {}}>
              {t("send")}
            </Button> */}
          </HStack>
        }
        width={500}
        style={{
          borderRadius: 50,
        }}
      >
        <SurveyDetails
          handleModalClose={handleModalClose}
          survey_item={survey_data}
        />
      </Modal>

      <Modal
        width={450}
        open={isDeleteModalOpen}
        onCancel={handleDeleteModalClose}
        onOk={() => {}}
        title={t("confirmation")}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleDeleteModalClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button type="primary" loading={false} onClick={() => {}}>
              {t("delete")}
            </Button>
          </HStack>
        }
      >
        <Text>{t("delete_institution_confirmation")}</Text>
      </Modal>
    </Box>
  );
};
export default SurveyList;
