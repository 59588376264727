import React, { useState } from "react";
import { HStack, Text, Image, Pressable, Box } from "native-base";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DocumentViewer from "@views/components/system/form/document_viewer";

const UnitDetails = (props) => {
  const { handleModalClose, units_item } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDocOpen, setIsDocOpen] = useState(false);

  const openViewDoc = () => {
    setIsDocOpen(true);
  };

  const closeViewDoc = () => {
    setIsDocOpen(false);
  };
  return (
    <Box overflow={"auto"}>
      <Text>{t("confirmation_message_units")}</Text>
      <Box my={5} alignItems={"center"}>
        <Pressable onPress={openViewDoc}>
          <Image
            source={require(`../../../../assets/icons/pdf_transparent.png`)}
            size={"md"}
          />
        </Pressable>
      </Box>

      <Text
        style={{
          fontWeight: "800",
        }}
      >
        {units_item?.units_question}
      </Text>
      <Text my={2}>{units_item?.units_question}</Text>
      <Box>
        <HStack alignItems={"center"}>
          <Text
            style={{
              fontWeight: "800",
            }}
          >
            {t("units_questions")}
          </Text>

          <Text mx={2}>{units_item?.unit}</Text>
        </HStack>
      </Box>
      <Box>
        <HStack alignItems={"center"}>
          <Text
            style={{
              fontWeight: "800",
            }}
          >
            {t("designations_title")}
          </Text>

          <Text>{units_item?.designation}</Text>
        </HStack>
      </Box>
      <DocumentViewer
        fileUrl={units_item?.units_document}
        title="Preview"
        isOpen={isDocOpen}
        onClose={closeViewDoc}
      />
    </Box>
  );
};
export default UnitDetails;
