import { gql } from "@apollo/client";

export const employee_list = gql`
  query get_employee_list(
    $filter: get_employee_list_filter_input
    $pagination: pagination_input
  ) {
    get_employee_list(filter: $filter, pagination: $pagination) {
      items {
        id
        designation {
          name
          id
          units {
            id
            name
          }
        }
        designation_id
        department_id
        mobile
        email_id
        name
        date_of_birth
        system_user {
          roles {
            id
            name
          }
        }
        department {
          id
          name
        }
        photo
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;
export const employee_create = gql`
  mutation create_employee($data: create_employee_input!) {
    create_employee(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const employee_update = gql`
  mutation update_employee($id: String!, $data: update_employee_input!) {
    update_employee(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const employee_delete = gql`
  mutation delete_employee($id: String!) {
    delete_employee(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
