import { gql } from "@apollo/client";

export const update_user_password = gql`
  mutation change_password($custom: change_password_input) {
    change_password(custom: $custom) {
      session_id
      user_id
      system_user {
        roles {
          id
          name
        }
        last_login_datetime
        username
      }
      error {
        status_code
        message
      }
      status
      page_accesses
      page_component_accesses
    }
  }
`;
export const change_user_password_mutation = gql`
  mutation change_password($custom: change_password_input) {
    change_password(custom: $custom) {
      session_id
      user_id
      system_user {
        roles {
          id
          name
        }
        last_login_datetime
        username
      }
      error {
        status_code
        message
      }
      status
      page_accesses
      page_component_accesses
    }
  }
`;
