import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";

import Entypo from "react-native-vector-icons/dist/Entypo";
import { Button } from "antd";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  admin_login_query,
  update_user_password,
} from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { retrieveItem, storeItem } from "@helpers/functions";
import DocumentViewer from "@views/components/system/form/document_viewer";

const EmployeeDetails = (props) => {
  const { handleModalClose, employee_item } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDocOpen, setIsDocOpen] = useState(false);

  const openViewDoc = () => {
    setIsDocOpen(true);
  };

  const closeViewDoc = () => {
    setIsDocOpen(false);
  };
  return (
    <Box overflow={"auto"}>
      <Text>{t("confirmation_message_circular")}</Text>
      <Box my={5} alignItems={"center"}>
        <Pressable onPress={openViewDoc}>
          <Image
            source={require(`../../../../assets/images/events-design-vector-12488777.jpg`)}
            size={"md"}
          />
        </Pressable>
      </Box>

      <Text
        style={{
          fontWeight: "800",
        }}
      >
        {employee_item?.employee_title}
      </Text>
      <Text my={2}>{employee_item?.employee_content}</Text>
      <Box>
        <HStack alignItems={"center"}>
          <Text
            style={{
              fontWeight: "800",
            }}
          >
            {t("units_title")}
          </Text>

          <Text mx={2}>{employee_item?.unit}</Text>
        </HStack>
      </Box>
      <Box>
        <HStack alignItems={"center"}>
          <Text
            style={{
              fontWeight: "800",
            }}
          >
            {t("designations_title")}
          </Text>

          <Text>{employee_item?.designation}</Text>
        </HStack>
      </Box>
      <DocumentViewer
        fileUrl={`../../../../assets/images/events-design-vector-12488777.jpg`}
        title="Preview"
        isOpen={isDocOpen}
        onClose={closeViewDoc}
      />
    </Box>
  );
};
export default EmployeeDetails;
