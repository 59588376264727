import { gql } from "@apollo/client";

export const get_announcement_list_query = gql`
 query get_announcement_list($filter: get_announcement_list_filter_input, $pagination: pagination_input, $sort: [sort_input]) {
  get_announcement_list(filter: $filter, pagination: $pagination, sort: $sort) {
    items {
      id
      title
      content
      file
      created_at
      notification{
        units{
          id
          name
        }
        departments{
          id
          name
        }
        designations{
          id
          name
        }
        employees{
          id
          name
        }
      }
    }
    pagination {
      total_count
      page_number
      page_limit
    }
    error {
      status_code
      message
    }
  }
}
`;

export const get_one_announcement_query = gql`
 query get_announcement($id: String!) {
  get_announcement(id: $id) {
    id
    title
    content
    file
    created_at
    notification {
      units {
        id
        name
      }
      departments {
        id
        name
      }
      designations {
        id
        name
      }
      employees {
        id
        name
      }
      target_employees {
        id
        name
      }
    }
  }
}
`;
export const create_announcement_mutation = gql`
  mutation create_announcement($data: create_announcement_input!) {
    create_announcement(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
