import { gql } from "@apollo/client";

export const get_circular_list_query = gql`
  query get_circular_list(
    $filter: get_circular_list_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_circular_list(filter: $filter, pagination: $pagination, sort: $sort) {
      items {
        id
        title
        content
        file
        created_at
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_circular_query = gql`
  query get_circular($id: String!) {
    get_circular(id: $id) {
      id
      title
      content
      document
      created_at
    }
  }
`;
export const create_circular_mutation = gql`
  mutation create_circular($data: create_circular_input!) {
    create_circular(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
