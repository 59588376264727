import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { Button, Col, Form, Modal, Progress, Row, Select } from "antd";
import { Label } from "reactstrap";
import { useHistory } from "react-router-dom";
import PublishsurveyUnit from "./publishsurvey_form_elements/publishsurvey_unit";
import PublishsurveyDepartment from "./publishsurvey_form_elements/publishsurvey_department";
import PublishsurveyDesignation from "./publishsurvey_form_elements/publishsurvey_designation";
import PublishsurveyEmployee from "./publishsurvey_form_elements/publishsurvey_employee";
import {
  department_list,
  dynamicClear,
  dynamicRequest,
  employee_list,
  get_all_designation,
  get_all_units,
  get_one_survey_query,
  publish_survey_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import PublishSurveyAudience from "./publishsurvey_form_elements/publishsurvey_audience";
import { toast } from "react-toastify/dist";
import Chart from "react-google-charts";
import { useTranslation } from "react-i18next";
import Employee from "../dynamic_fields/announcement_form_elements/employee";
import Designation from "../dynamic_fields/announcement_form_elements/designation";
import Department from "../dynamic_fields/announcement_form_elements/department";
import Unit from "../dynamic_fields/announcement_form_elements/unit";
import Audience from "../dynamic_fields/announcement_form_elements/audience";
import CustomSelect from "../custom_selects/custom_selects";

const PublishSurveyDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleBack = () => {
    history.push("/survey");
  };
  const [departments, setDepartments] = useState([]);
  const [units, setUnits] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [survey_values, setSurveyValues] = useState([]);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState([["option", "score"]]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [showUnitSelect, setShowUnitSelect] = useState(false);
  const [showDepartmentSelect, setShowDepartmentSelect] = useState(false);
  const [showDesignationSelect, setShowDesignationSelect] = useState(false);
  const [showEmployeeSelect, setShowEmployeeSelect] = useState(false);

  const { id } = useParams();

  const { items: unit_items } = useDynamicSelector("get_unit_list");
  const { items: department_lists } = useDynamicSelector("get_department_list");
  const { items: designation_items } = useDynamicSelector(
    "get_designation_list"
  );
  const { items: get_all_employee_list } =
    useDynamicSelector("get_employee_list");

  const { title, questions } = useDynamicSelector("get_survey");

  const {
    status: publish_survey_status,
    error: publish_survey_error,
    loading: publish_survey_loading,
  } = useDynamicSelector("publish_survey");

  const handleFirstSelectChange = (value) => {
    setSelectedValue(value);

    setShowUnitSelect(
      value === "unit" ||
        value === "department" ||
        value === "designation" ||
        value === "employee"
    );
    setShowDepartmentSelect(value === "department" || value === "employee");
    setShowDesignationSelect(
      value === "designation" || value === "employee" || value === "department"
    );
    setShowEmployeeSelect(value === "employee");
  };
  useEffect(() => {
    if (questions?.length > 0) {
      const pieData = [["OPTIONS", "Score"]];
      questions[0]?.options?.forEach((option) => {
        pieData.push([option.option, option.score]);
      });

      setChartData(pieData);
    }
  }, [questions]);

  useEffect(() => {
    if (publish_survey_status === "success") {
      toast.success("Published successfully");
      dispatch(dynamicClear("publish_survey"));
      handleBack();
    } else if (publish_survey_error) {
      toast.error(publish_survey_error?.message);
      dispatch(dynamicClear("publish_survey"));
    }
  }, [publish_survey_status, publish_survey_error]);

  useEffect(() => {
    getOneSurvey();
    getAllDesignation();
    getAllUnits();
  }, []);

  const getAllUnits = () => {
    let key = [{ key: "get_unit_list", loading: true }];
    let query = get_all_units;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllDesignation = () => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  const getOneSurvey = () => {
    let key = [{ key: "get_survey", loading: true }];
    let query = get_one_survey_query;
    let variable = { id };
    dispatch(dynamicRequest(key, query, variable));
  };

  const audience_items = [
    {
      id: "unit",
      name: "Unit",
    },
    {
      id: "designation",
      name: "Designation",
    },
    {
      id: "department",
      name: "Department",
    },
    {
      id: "employee",
      name: "Employee",
    },
  ];
  const publishSurveyDetails = () => {
    let values = survey_values;
    let key = [{ key: "publish_survey", loading: true }];
    let query = publish_survey_mutation;
    delete values.message;
    let variables = {
      custom: {
        survey_id: id,
        notification: {
          audience: values?.audience,
          unit_ids: values?.units,
          department_ids: values?.departments,
          designation_ids: values?.designations,
          employee_ids: values?.employees,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleSubmit = (values) => {
    handleModalOpen(values);
  };

  const dataForBarChart = (options) => {
    let bar_data = [["OPTIONS", "SCORE"]];
    options?.forEach((option) => {
      bar_data.push([option.option, option.score]);
    });
    return bar_data;
  };
  const dataForPieChart = (options) => {
    let pie_data = [["OPTIONS", "SCORE"]];
    options?.forEach((option) => {
      pie_data.push([option.option, option.score]);
    });
    return pie_data;
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = (values) => {
    setIsModalOpen(true);
    setSurveyValues(values);
  };

  const getAllEmployee = (unit_ids, department_ids) => {
    let key = [{ key: "get_employee_list", loading: true }];
    let query = employee_list;
    let variable = {
      filter: {
        unit_ids,
        department_ids,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllDepartment = (unit_ids) => {
    let key = [{ key: "get_department_list", loading: true }];
    let query = department_list;
    let variable = {
      filter: {
        unit_ids,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const handleUnitChange = (values) => {
    getAllEmployee(values);
    getAllDepartment(values);
  };
  const handleDepartmentChange = (values) => {
    getAllEmployee([], values, []);
  };
  const handleDesignationChange = (values) => {
    console.log("values", values);
  };
  const handleEmployeeChange = (values) => {
    console.log("values", values);
  };

  return (
    <Box backgroundColor={"#fff"} flex={1} style={{ padding: "20px" }}>
      <Box py="20px">
        <Text fontSize={"20px"} fontWeight={"bold"}>
          {title}
        </Text>
      </Box>
      <div>
        {questions?.map((question) => (
          <div key={question.id}>
            <Box py="20px">
              <Text type="rich_text" my="20px" fontSize={"15px"}>
                {/* {question.content} */}
                <div dangerouslySetInnerHTML={{ __html: question.content }} />
              </Text>
            </Box>
            <HStack alignItems={"center"} width={"100%"}>
              <Box width={"50%"}>
                <Chart
                  height={"300px"}
                  chartType="PieChart"
                  data={dataForPieChart(question?.options)}
                  options={{
                    backgroundColor: "#fff",
                    is3D: true,
                    pieSliceText: "value",
                  }}
                />
              </Box>
              <Box
                style={{
                  backgroundColor: "#fff",
                }}
                width={"50%"}
              >
                <Chart
                  style={{
                    backgroundColor: "#fff",
                    padding: 5,
                  }}
                  height={"300px"}
                  chartType="Bar"
                  data={dataForBarChart(question?.options)}
                  options={{
                    backgroundColor: "#ff0000",
                    is3D: true,
                    pieSliceText: "value",
                    width: "100%",
                    vAxis: {
                      minValue: 0, // Set minimum value of the vertical axis to 0
                      viewWindow: {
                        min: 0, // Set the minimum view window value to 0
                      },
                    },
                  }}
                />
              </Box>
            </HStack>
            <Box my={10} alignItems={"center"} width={"100%"}>
              {question?.options?.map((option) => {
                return (
                  <VStack width={"50%"}>
                    <Box>
                      <Text>{option.option}</Text>
                    </Box>

                    <Box>
                      <Progress
                        percent={option.score}
                        strokeColor={{
                          from: "navy",
                          to: "navy",
                        }}
                        size={[300, 20]}
                      />
                    </Box>
                  </VStack>
                );
              })}
            </Box>
          </div>
        ))}
      </div>
      <Form layout="vertical" width="100%" onFinish={handleSubmit}>
        <Box my={5} mx={5}>
          {/* <Row gutter={[13][13]} style={{ gap: "10px" }}>
            <Col span={11}>
              <Audience
                handleFirstSelectChange={handleFirstSelectChange}
                AudienceOptions={audience_items}
              />
            </Col>
          </Row>
          <Row gutter={[13][13]} style={{ gap: "10px" }}>
            {showUnitSelect && (
              <Col span={11}>
                <Unit UnitOptions={unit_items} />
              </Col>
            )}
            {showDepartmentSelect && (
              <Col span={11}>
                <Department DepartmentOption={department_lists} />
              </Col>
            )}
          </Row>
          <Row gutter={[13][13]} style={{ gap: "10px" }}>
            {showDesignationSelect && (
              <Col span={11}>
                <Designation DesignationOption={designation_items} />
              </Col>
            )}
            {showEmployeeSelect && (
              <Col span={11}>
                <Employee EmployeeOptions={get_all_employee_list} />
              </Col>
            )}
          </Row> */}
          <CustomSelect
            handleFirstSelectChange={handleFirstSelectChange}
            AudienceOptions={audience_items}
            showUnitSelect={showUnitSelect}
            showDepartmentSelect={showDepartmentSelect}
            showDesignationSelect={showDesignationSelect}
            showEmployeeSelect={showEmployeeSelect}
            unit_items={unit_items}
            department_lists={department_lists}
            designation_items={designation_items}
            get_all_employee_list={get_all_employee_list}
            handleUnitChange={handleUnitChange}
            handleDepartmentChange={handleDepartmentChange}
            handleDesignationChange={handleDesignationChange}
            handleEmployeeChange={handleEmployeeChange}
          />
        </Box>

        <HStack space="10px" justifyContent={"flex-end"} marginRight="70px">
          <Button
            onClick={handleBack}
            style={{
              position: "absolute",
              marginRight: "20px",
              bottom: "43px",
            }}
          >
            Back
          </Button>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                position: "absolute",
                marginLeft: "80%",
                bottom: "20px",
              }}
              // onClick={handlePublish}
            >
              Publish
            </Button>
          </Form.Item>
        </HStack>
      </Form>

      <Modal
        width={450}
        open={is_modal_open}
        onCancel={handleModalClose}
        onOk={() => {
          publishSurveyDetails();
        }}
        title={t("confirmation")}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleModalClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              loading={publish_survey_loading}
              onClick={() => {
                publishSurveyDetails();
              }}
            >
              {t("publish")}
            </Button>
          </HStack>
        }
      >
        <Text>{t("publish_confirmation")}</Text>
      </Modal>
    </Box>
  );
};
export default PublishSurveyDetails;
