import DepartmentList from "@views/components/user/department/department_list";
import departmentDetails from "@views/components/user/dashboard/dashboard_details";
import { Box } from "native-base";
import React from "react";

const Department = () => {
  return (
    <Box flex={1}>
      <DepartmentList />
    </Box>
  );
};
export default Department;
