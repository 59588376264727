import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import {
  create_designation_mutation,
  delete_designation_mutation,
  dynamicClear,
  dynamicRequest,
  get_all_designation,
  update_designation_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import Form from "@views/components/system/form";
import { Button, Input, Modal, Pagination, Table } from "antd";
import DesignationDetails from "./designation_details";
import { getUUID } from "@helpers/functions";
import { toast } from "react-hot-toast";

const DesignationList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [is_open, setIsOpen] = useState(false);
  const [delete_id, setDeleteId] = useState("");
  const [delete_designation_name, setDeleteDesignationName] = useState("");

  const [incharge_id, setInchargeId] = useState("");
  const [get_one_id, setGetOneId] = useState("");
  const [header_type, setHeaderType] = useState("");
  const [initial_data, setInitialData] = useState({});
  const [designation_data, setDesignationData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [get_search_string, setSearchString] = useState("");

  const {
    status: create_designation_status,
    error: create_designation_error,
    loading: create_designation_loading,
  } = useDynamicSelector("create_designation");
  const {
    status: update_designation_status,
    error: update_designation_error,
    loading: update_designation_loading,
  } = useDynamicSelector("update_designation");
  const {
    status: delete_designation_status,
    error: delete_designation_error,
    loading: delete_designation_loading,
  } = useDynamicSelector("delete_designation");
  const {
    items: designation_items,
    pagination: designation_items_pagination,
    loading: designation_items_loading,
  } = useDynamicSelector("get_designation_list");

  const items = [
    {
      id: "department_id_1",
      name: "Designation 1",
    },
    {
      id: "department_id_2",
      name: "Designation 2",
    },
    {
      id: "department_id_3",
      name: "Designation 3",
    },
  ];

  const createDesignation = (values) => {
    let uuid = getUUID();

    let key = [{ key: "create_designation", loading: true }];
    let query = create_designation_mutation;
    let variables = {
      data: {
        name: values?.name,
        id: uuid,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const updateDesignation = (values) => {
    let key = [{ key: "update_designation", loading: true }];
    let query = update_designation_mutation;
    let variables = {
      id: get_one_id,
      data: {
        id: get_one_id,
        name: values?.name,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const deleteDesignation = () => {
    let key = [{ key: "delete_designation", loading: true }];
    let query = delete_designation_mutation;
    let variables = {
      id: delete_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const fetchData = (page_number, page_limit) => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variable = {
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const getAllDesignation = () => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variables = {
      filter: { search_string: get_search_string },
      pagination: {
        page_number: current,
        page_limit: current_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const onAdd = () => {
    setHeaderType("add");
    setIsOpen(true);
    setInitialData({});
    setGetOneId("");
  };

  const onEdit = (id) => {
    setHeaderType("edit");
    setGetOneId(id);
  };

  const onView = (id, item) => {
    handleModalOpen(item);
  };

  const onDelete = (id) => {
    console.log("onDelete", id);
  };

  const onChange = (
    page_number,
    page_limit,
    sort_key,
    sort_direction,
    search_string,
    filter
  ) => {
    console.log(
      "onPageChange",
      page_number,
      page_limit,
      sort_key,
      sort_direction,
      search_string,
      filter
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (item) => {
    setIsModalOpen(true);
    setDepartmentData(item);
  };

  const handleDeleteModalOpen = (record) => {
    setIsDeleteModalOpen(true);
    setDeleteId(record?.id);
    setDeleteDesignationName(record);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const onFormCancel = () => {
    setIsOpen(false);
    setGetOneId("");
    setInitialData({});
  };

  const onFormChange = (values) => {
    console.log("onFormChange", values);
  };

  const onFormSubmit = (values) => {
    if (header_type === "edit") {
      updateDesignation(values);
    } else {
      createDesignation(values);
    }
  };

  const columns = [
    {
      title: t("designation_name"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "20%",
      render: (record, rowIndex) => {
        return (
          <HStack>
            <Pressable
              onPress={() => {
                onEdit(record?.id);
              }}
              mx={2}
            >
              <AiOutlineEdit size={20} color="#000000" />
            </Pressable>
            <Pressable
              onPress={() => {
                handleDeleteModalOpen(record);
              }}
            >
              <AiOutlineDelete size={20} color="#000000" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];

  const form_fields = [
    {
      field: "name",
      label: t("name"),
      type: "text",
      rules: [
        { required: true, message: t("error:designation_name_is_required") },
      ],
    },

    // {
    //   field: "department",
    //   label: t("department"),
    //   type: "select",
    //   rules: [{ required: true, message: t("designation_unit_is_required") }],
    //   options: department_items,
    //   isMulti: true,
    // },
  ];
  useEffect(() => {
    if (get_one_id) {
      let getOneItem = designation_items?.find((x) => {
        return x.id === get_one_id;
      });
      setInitialData(getOneItem);
      setIsOpen(true);
    }
  }, [get_one_id]);

  useEffect(() => {
    if (create_designation_status === "success") {
      toast.success(t("Designation Created"));
      dispatch(dynamicClear("create_designation"));
      onFormCancel();
      getAllDesignation();
    } else if (create_designation_error) {
      toast.error(create_designation_error?.message);
      dispatch(dynamicClear("create_designation"));
    } else if (update_designation_status === "success") {
      toast.success(t("Designation Updated"));
      dispatch(dynamicClear("update_designation"));
      onFormCancel();
      getAllDesignation();
    } else if (update_designation_error) {
      toast.error(update_designation_error?.message);
      dispatch(dynamicClear("update_designation"));
    } else if (delete_designation_status === "success") {
      toast.success(t("Designation Deleted"));
      dispatch(dynamicClear("delete_designation"));
      getAllDesignation();
      handleDeleteModalClose();
    } else if (delete_designation_error) {
      toast.error(delete_designation_error?.message);
      dispatch(dynamicClear("delete_designation"));
    }
  }, [
    create_designation_status,
    create_designation_error,
    update_designation_status,
    update_designation_error,
    delete_designation_status,
    delete_designation_error,
  ]);

  useEffect(() => {
    getAllDesignation({ search_string: get_search_string });
  }, [current, current_limit, get_search_string]);

  useEffect(() => {
    getAllDesignation();
  }, []);

  const handleSearch = (value) => {
    setSearchString(value);
  };

  return (
    <Box flex={1}>
      <Box padding={5}>
        <Box py="20px">
          <Text fontSize={20} bold>
            {t("designations")}
          </Text>
        </Box>
        <HStack mb={5} justifyContent={"flex-end"} mx={5} space={3}>
          <Box width={"200px"}>
            <Input.Search label="Search" allowClear onSearch={handleSearch} />
          </Box>
          <Box>
            <Button type="primary" onClick={onAdd}>
              {t("add_designation")}
            </Button>
          </Box>
        </HStack>
        <Table
          dataSource={designation_items}
          columns={columns}
          loading={designation_items_loading}
          onChange={onChange}
          pagination={false}
        />
        <Box alignItems={"flex-end"} my={5}>
          <HStack alignItems={"center"}>
            <Text mx={2} fontSize="md">
              {`Total ${
                designation_items_pagination?.total_count
                  ? designation_items_pagination?.total_count
                  : 0
              } Items`}
            </Text>
            <Pagination
              showSizeChanger={true}
              hideOnSinglePage={false}
              onChange={fetchData}
              total={designation_items_pagination?.total_count}
              pageSizeOptions={[5, 10, 15, 20, 50]}
            />
          </HStack>
        </Box>
      </Box>
      <Form
        title={
          header_type === "edit" ? t("edit_designation") : t("add_designation")
        }
        submitLabel="Save"
        isSubmitLoading={
          create_designation_loading || update_designation_loading
        }
        isOpen={is_open}
        width={500}
        initialData={initial_data}
        fields={form_fields}
        onCancel={onFormCancel}
        onSubmit={onFormSubmit}
        onChange={onFormChange}
      />
      <Modal
        title={t("preview")}
        open={is_modal_open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleModalClose();
              }}
            >
              {t("back")}
            </Button>
            <Button type="primary" loading={false} onClick={() => {}}>
              {t("send")}
            </Button>
          </HStack>
        }
        width={500}
        style={{
          borderRadius: 50,
        }}
      >
        <DesignationDetails
          handleModalClose={handleModalClose}
          designation_item={designation_data}
        />
      </Modal>

      <Modal
        width={450}
        open={isDeleteModalOpen}
        onCancel={handleDeleteModalClose}
        onOk={() => {
          deleteDesignation();
        }}
        title={t("confirmation")}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleDeleteModalClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              loading={delete_designation_loading}
              onClick={() => {
                deleteDesignation();
              }}
            >
              {t("delete")}
            </Button>
          </HStack>
        }
      >
        <Text>
          {t("are_you_sure_you_want_to_delete_this_designation", {
            name: delete_designation_name?.name,
          })}
        </Text>
      </Modal>
    </Box>
  );
};
export default DesignationList;
