import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import {
  create_circular_mutation,
  department_list,
  dynamicClear,
  dynamicRequest,
  employee_list,
  get_all_designation,
  get_all_units,
  get_circular_list_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import Form from "@views/components/system/form";
import { Button, Input, Modal, Pagination, Table } from "antd";
import CircularDetails from "./circular_details";
import { getUUID, navigate } from "@helpers/functions";
import { toast } from "react-hot-toast";
import { ROUTES } from "@views/routes/my_routes";
const CircularsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [is_open, setIsOpen] = useState(false);
  const [delete_id, setDeleteId] = useState("");
  const [incharge_id, setInchargeId] = useState("");
  const [get_one_id, setGetOneId] = useState("");
  const [header_type, setHeaderType] = useState("");
  const [initial_data, setInitialData] = useState({});
  const [circular_data, setCircularData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [circular_table_items, setCircularTableItems] = useState([]);
  const [get_search_string, setSearchString] = useState("");

  const {
    items: circular_items,
    pagination: circular_items_pagination,
    loading: circular_items_loading,
  } = useDynamicSelector("get_circular_list");
  const { items: unit_items } = useDynamicSelector("get_unit_list");
  const { items: department_lists } = useDynamicSelector("get_department_list");
  const { items: get_all_employee_list } =
    useDynamicSelector("get_employee_list");
  const { items: designation_items } = useDynamicSelector(
    "get_designation_list"
  );

  const {
    status: create_circular_status,
    error: create_circular_error,
    loading: create_circular_loading,
  } = useDynamicSelector("create_circular");

  const audience_items = [
    {
      id: "unit",
      name: "Unit",
    },
    {
      id: "designation",
      name: "Designation",
    },
    {
      id: "department",
      name: "Department",
    },
    {
      id: "employee",
      name: "Employee",
    },
  ];

  const getAllEmployee = (search_string) => {
    let key = [{ key: "get_employee_list", loading: true }];
    let query = employee_list;
    let variable = { search_string: search_string };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllUnits = () => {
    let key = [{ key: "get_unit_list", loading: true }];
    let query = get_all_units;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAllDepartment = () => {
    let key = [{ key: "get_department_list", loading: true }];
    let query = department_list;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllDesignation = () => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const createCircular = (values) => {
    let splitUrl = values?.file?.[0]?.url?.split("/");
    let desiredValue = splitUrl[splitUrl.length - 1];
    let uuid = getUUID();
    let key = [{ key: "create_circular", loading: true }];
    let query = create_circular_mutation;
    let variable = {
      data: {
        title: values?.circular_title,
        file: `circular/${desiredValue}` || null,

        id: uuid,
        content: values?.circular_content,
        notification: {
          audience: values?.audience,
          unit_ids: values?.units,
          department_ids: values?.departments,
          designation_ids: values?.designations,
          employee_ids: values?.employees,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllCircular = () => {
    let key = [{ key: "get_circular_list", loading: true }];
    let query = get_circular_list_query;
    let variable = {
      filter: { search_string: get_search_string },
      pagination: {
        page_number: current,
        page_limit: current_limit,
      },
      sort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const fetchData = (page_number, page_limit) => {
    let key = [{ key: "get_circular_list", loading: true }];
    let query = get_circular_list_query;
    let variable = {
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
      sort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const onAdd = () => {
    setHeaderType("add");
    // setIsOpen(true);
    navigate(ROUTES.EDIT_CIRCULAR);
    setInitialData({});
    setGetOneId("");
  };

  const onEdit = (id) => {
    setHeaderType("edit");
    setGetOneId(id);
  };

  const onView = (item) => {
    console.log("item", item);

    handleModalOpen(item);
  };
  const onDelete = (id) => {
    console.log("onDelete", id);
  };

  const onChange = (
    page_number,
    page_limit,
    sort_key,
    sort_direction,
    search_string,
    filter
  ) => {
    console.log(
      "onPageChange",
      page_number,
      page_limit,
      sort_key,
      sort_direction,
      search_string,
      filter
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (item) => {
    setIsModalOpen(true);
    setCircularData(item);
  };

  const handleDeleteModalOpen = (id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(id);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const onFormCancel = () => {
    setIsOpen(false);
    setGetOneId("");
    setInitialData({});
  };

  const onFormChange = (values) => {
    console.log("onFormChange", values);
  };

  const onFormSubmit = (values) => {
    createCircular(values);
  };

  const columns = [
    {
      title: t("circular_title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "20%",
      render: (record, rowIndex) => {
        return (
          <Pressable
            onPress={() => {
              onView(record);
            }}
          >
            <AiOutlineEye size={20} color="#000000" />
          </Pressable>
        );
      },
    },
  ];

  const form_fields = [
    {
      field: "circular_title",
      label: t("circular_title"),
      type: "text",
      rules: [
        { required: true, message: t("error:circular_title_is_required") },
      ],
    },
    {
      field: "circular_content",
      label: t("circular_content"),
      type: "rich_text",
      rules: [
        { required: true, message: t("error:circular_content_is_required") },
      ],
    },
    {
      field: "audience",
      label: t("audience"),
      type: "select",
      // rules: [
      //   { required: true, message: t("error:announcement_unit_is_required") },
      // ],
      options: audience_items,
    },
    {
      field: "units",
      label: t("unit"),
      type: "select",
      // rules: [
      //   { required: true, message: t("error:announcement_unit_is_required") },
      // ],
      options: unit_items,
      isMulti: true,
    },
    {
      field: "departments",
      label: t("department"),
      type: "select",
      // rules: [
      //   {
      //     required: true,
      //     message: t("error:announcement_department_is_required"),
      //   },
      // ],
      options: department_lists,
      isMulti: true,
    },
    {
      field: "designations",
      label: t("designation"),
      type: "select",
      // rules: [
      //   {
      //     required: true,
      //     message: t("error:announcement_designation_is_required"),
      //   },
      // ],
      options: designation_items,
      isMulti: true,
    },
    {
      field: "employees",
      label: t("employee"),
      type: "select",
      // rules: [
      //   {
      //     required: true,
      //     message: t("error:announcement_employee_is_required"),
      //   },
      // ],
      options: get_all_employee_list,
      isMulti: true,
    },
    {
      field: "file",
      label: t("circular_document"),
      type: "file",
      upload_type: "circular",

      // rules: [
      //   {
      //     required: true,
      //     message: t("error:circular_image_upload_is_required"),
      //   },
      // ],
    },
  ];

  useEffect(() => {
    if (create_circular_status === "success") {
      toast.success(t("Circular Sent"));
      dispatch(dynamicClear("create_circular"));
      onFormCancel();
      getAllCircular();
    } else if (create_circular_error) {
      toast.error(create_circular_error?.message);
      dispatch(dynamicClear("create_circular"));
    }
  }, [create_circular_status, create_circular_error]);
  useEffect(() => {
    if (get_one_id) {
      let getOneItem = circular_items?.find((x) => {
        return x.id === get_one_id;
      });
      setInitialData({ ...getOneItem, circular_document: [{}] });
      setIsOpen(true);
    }
  }, [get_one_id]);

  useEffect(() => {
    getAllCircular();
    getAllEmployee();
    getAllDesignation();
    getAllUnits();
    getAllDepartment();
  }, []);

  useEffect(() => {
    getAllCircular({ search_string: get_search_string });
  }, [current, current_limit, get_search_string]);

  useEffect(() => {
    if (circular_items) {
      setCircularTableItems(circular_items);
    }
  }, [circular_items]);

  const handleSearch = (value) => {
    setSearchString(value);
  };

  return (
    <Box flex={1}>
      <Box padding={5}>
        <Box py="20px">
          <Text fontSize={20} bold>
            {t("circulars")}
          </Text>
        </Box>
        <HStack mb={5} justifyContent={"flex-end"} mx={5} space={3}>
          <Box width={"200px"}>
            <Input.Search label="Search" allowClear onSearch={handleSearch} />
          </Box>
          <Box>
            <Button type="primary" onClick={onAdd}>
              {t("add_circular")}
            </Button>
          </Box>
        </HStack>

        <Table
          dataSource={circular_table_items}
          columns={columns}
          loading={circular_items_loading}
          onChange={onChange}
          pagination={false}
        />
        <Box alignItems={"flex-end"} my={5}>
          <HStack alignItems={"center"}>
            <Text mx={2} fontSize="md">
              {`Total ${
                circular_items_pagination?.total_count
                  ? circular_items_pagination?.total_count
                  : 0
              } Items`}
            </Text>
            <Pagination
              showSizeChanger={true}
              hideOnSinglePage={false}
              onChange={fetchData}
              total={circular_items_pagination?.total_count}
              pageSizeOptions={[5, 10, 15, 20, 50]}
            />
          </HStack>
        </Box>
      </Box>

      <Form
        title={header_type === "edit" ? t("edit_circular") : t("add_circular")}
        submitLabel="Save"
        isSubmitLoading={create_circular_loading}
        isOpen={is_open}
        width={500}
        initialData={initial_data}
        fields={form_fields}
        onCancel={onFormCancel}
        onSubmit={onFormSubmit}
        onChange={onFormChange}
      />

      <Modal
        title={circular_data?.title}
        open={is_modal_open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleModalClose();
              }}
            >
              {t("close")}
            </Button>
            {/* <Button type="primary" loading={false} onClick={() => {}}>
              {t("send")}
            </Button> */}
          </HStack>
        }
        width={500}
        style={{
          borderRadius: 50,
        }}
      >
        <CircularDetails
          handleModalClose={handleModalClose}
          circular_item={circular_data}
        />
      </Modal>

      <Modal
        width={450}
        open={isDeleteModalOpen}
        onCancel={handleDeleteModalClose}
        onOk={() => {}}
        title={t("confirmation")}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleDeleteModalClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button type="primary" loading={false} onClick={() => {}}>
              {t("delete")}
            </Button>
          </HStack>
        }
      >
        <Text>{t("are_you_sure_you_want_to_delete_this_circulars?")}</Text>
      </Modal>
    </Box>
  );
};
export default CircularsList;
