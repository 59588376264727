import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";
import Entypo from "react-native-vector-icons/dist/Entypo";
import { Button, Card, Space } from "antd";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  admin_login_query,
  update_user_password,
} from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { retrieveItem, storeItem } from "@helpers/functions";
import DocumentViewer from "@views/components/system/form/document_viewer";
import { CgSpaceBetween } from "react-icons/cg";
import { layout, space } from "styled-system";

const SurveyDetails = (props) => {
  const { handleModalClose, survey_item } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box overflow={"auto"}>
      {survey_item?.questions?.map((x, i) => {
        return (
          <Box key={i}>
            <Text mt={i === 0 ? 0 : 5}>
              <div dangerouslySetInnerHTML={{ __html: x?.content }} />
            </Text>
            {x.options.map((opt, ind) => {
              return <Text mt={ind === 0 ? 2 : 0}>{opt.option}</Text>;
            })}
            <Divider />
          </Box>
        );
      })}
    </Box>
  );
};
export default SurveyDetails;
