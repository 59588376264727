import { gql } from "@apollo/client";

export const create_designation_mutation = gql`
  mutation create_designation($data: create_designation_input!) {
    create_designation(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const update_designation_mutation = gql`
  mutation update_designation($id: String!, $data: update_designation_input!) {
    update_designation(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_designation_mutation = gql`
  mutation delete_designation($id: String!) {
    delete_designation(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_all_designation = gql`
  query get_designation_list(
    $pagination: pagination_input
    $filter: get_designation_list_filter_input
  ) {
    get_designation_list(pagination: $pagination, filter: $filter) {
      items {
        id
        name
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;
