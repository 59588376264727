import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

import { retrieveItem } from "@helpers/functions";
import toast from "react-hot-toast";
//import { logoutRequest } from "../../redux";

const BASE_URL = "https://avnl.vertace.org/api/graphql";
// const BASE_URL = "http://localhost:6330/graphql";
// const BASE_URL="http://192.168.1.47:6330/graphql"

// process.env.REACT_APP_BASE_URL;

const httpLink = createHttpLink({
  uri: BASE_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const user = retrieveItem("session_id");
  return {
    headers: {
      ...headers,

      session_id: user ? user : "",
    },
  };
});
let toastShown = false;

const logout = async () => {
  if (!toastShown) {
    toast.error("Not authorized");
    toastShown = true;
  }
  await setTimeout(() => {
    window.localStorage.clear();
    window.location.replace("/login");
    toastShown = false;
  }, 1500);
};

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

export async function QueryRequest(query, variables, dispatch) {
  try {
    let response = await client.query({
      query,
      variables,
    });
    console.log("response", response);

    if (response?.errors?.length !== 0 && response?.errors && response) {
      let isUnauthorized =
        (await JSON.stringify(response).indexOf("Not authorized")) > -1;
      if (isUnauthorized && dispatch) {
        logout();
        return null;
      }
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));
    }
    return { req_error: e };
  }
}
export async function MutateRequest(mutation, variables, dispatch) {
  let response = null;
  try {
    response = await client.mutate({
      mutation,
      variables,
    });
    if (response?.errors?.length !== 0 && response?.errors && response) {
      let isUnauthorized =
        (await JSON.stringify(response).indexOf("Not authorized")) > -1;
      if (isUnauthorized && dispatch) {
        logout();
        return null;
      }
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));
    }
    return { req_error: e };
  }
}
