import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  Box,
  Center,
  HStack,
  Image,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { retrieveItem } from "@helpers/functions";

const Sidebar = ({ menu_list }) => {
  const { t } = useTranslation();
  const pathname = window.location.pathname;
  const [collapsed, setCollapsed] = useState(false);
  const [menu_items, setMenuItems] = useState([]);
  const [selectedKey, setSelectedKey] = useState(pathname.substr(1));
  let page_accesses = retrieveItem("page_accesses");
  const onMenuItemClick = () => {
    let _pathname = window.location.pathname;
    setSelectedKey(_pathname.substr(1));
  };

  useEffect(() => {
    let _items = processItems(menu_list);
    setMenuItems(_items);
  }, [menu_list]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  function getItem(label, key, icon, url, accessKey, children) {
    let menu_label = label;
    if (url) {
      menu_label = (
        <Link to={url}>
          <span>{label}</span>
        </Link>
      );
    }
    return {
      key,
      icon,
      children,
      label: menu_label,
    };
  }

  const processItems = (_menu_list) => {
    let _items = [];
    for (let i = 0; i < _menu_list.length; i++) {
      let _menu = _menu_list[i];
      if (_menu.accessKey && page_accesses?.indexOf(_menu.accessKey) === -1) {
        continue;
      }
      let _children = null;
      if (_menu.children) {
        _children = processItems(_menu.children);
      }
      let _item = getItem(
        _menu.label,
        _menu.key,
        _menu.icon,
        _menu.url,
        _menu.accessKey,
        _children
      );
      _items.push(_item);
    }
    return _items;
  };

  return (
    <div style={{ display: "flex" }}>
      <VStack>
        {/* <HStack h="80px" bg="#042446">
          <Center w="80px" h="80px">
            <Pressable onPress={toggleCollapsed}>
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ fontSize: "22px", color: "white" }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ fontSize: "22px", color: "white" }}
                />
              )}
            </Pressable>
          </Center>
          {!collapsed && (
            <Box flex="1" justifyContent="center">
              <Text bold fontSize={18} color={"#fff"}>
                {"AVNL"}
              </Text>
            
            </Box>
          )}
        </HStack> */}
        <Menu
          className="sidebar-menu"
          selectedKeys={[selectedKey]}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          items={menu_items}
          onSelect={onMenuItemClick}
        ></Menu>
      </VStack>
    </div>
  );
};
export default Sidebar;
