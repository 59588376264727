import CryptoJS from "crypto-js";

export const encryptData = (data) =>
  CryptoJS.AES.encrypt(
    JSON.stringify(data),
    "4Y1oO0ElwMgib9bwVyglYxqhdpWBFpxw"
  ).toString();

export const DynamicEncryptData = (data, key) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();

export const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(
    ciphertext,
    "4Y1oO0ElwMgib9bwVyglYxqhdpWBFpxw"
  );
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};
