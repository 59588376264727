import React from "react";
import { dynamicSet } from "@services/redux";
import { BuildOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
const ManagePageComponent = (props) => {
  const { page_id } = props;
  const dispatch = useDispatch();

  const openAddPageComponent = () => {
    dispatch(dynamicSet("page_component_page_id", page_id));
    dispatch(dynamicSet("page_component_action", "Add"));
  };

  return (
    <BuildOutlined
      style={{ cursor: "pointer", color: "brown" }}
      onClick={openAddPageComponent}
    />
  );
};
export default ManagePageComponent;
