import React, { useEffect, useState } from "react";
import { Button, Drawer, Space, Form as ANTForm, Input } from "antd";
import { Scrollbars } from "react-custom-scrollbars-2";
import FormItem, { NumberBox, Password, TextArea, TextBox } from "./form_item";

const Form = (props) => {
  const {
    title,
    type,
    submitLabel,
    isSubmitLoading,
    width,
    isOpen,
    initialData,
    fields,
    onCancel,
    onSubmit,
    onChange,
  } = props;
  const [form] = ANTForm.useForm();

  useEffect(() => {
    if (form && initialData) {
      form.setFieldsValue({ ...initialData });
    }
  }, [form, initialData]);
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        form.resetFields();
      }, 600);
    }
  }, [isOpen]);
  return (
    <Drawer
      title={title}
      placement="right"
      width={width || 500}
      onClose={onCancel}
      open={isOpen}
      extra={
        <Space>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type="primary"
            loading={isSubmitLoading}
            onClick={() => {
              form.submit();
            }}
          >
            {submitLabel || "OK"}
          </Button>
        </Space>
      }
    >
      <Scrollbars style={{ width: "96%", margin: "2%" }}>
        <ANTForm
          layout={"vertical"}
          form={form}
          // initialValues={initialData}
          onValuesChange={onChange}
          onFinish={onSubmit}
          style={{ maxWidth: width || 500 }}
          scrollToFirstError
        >
          {fields &&
            fields.map((field, field_index) => {
              return (
                <FormItem key={field_index} index={field_index} {...field} />
              );
            })}
        </ANTForm>
      </Scrollbars>
    </Drawer>
  );
};
export default Form;
