import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import React from "react";

const Employee = (props) => {
  const { EmployeeOptions, onChange } = props;

  return (
    <AntdDynamicForm.Select
      options={EmployeeOptions}
      label="Employee"
      field={"employees"}
      isMulti
      onChange={onChange}
      labelCol={{ span: 2 }}
      wrapperCol={{ span: 20 }}
      rules={[
        {
          required: true,
          message: "Employee is required!",
        },
      ]}
    />
  );
};

export default Employee;
