import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import React from "react";

const Designation = (props) => {
  const { DesignationOption, onChange } = props;
  return (
    <AntdDynamicForm.Select
      options={DesignationOption}
      label="Designation"
      field={"designations"}
      isMulti
      onChange={onChange}
      labelCol={{ span: 2 }}
      wrapperCol={{ span: 20 }}
      rules={[
        {
          required: true,
          message: "Designation is required!",
        },
      ]}
    />
  );
};

export default Designation;
