import { gql } from "@apollo/client";
export const file_query = gql`
  query {
    get_photo_upload_url {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;
export const create_file_upload_query = gql`
  query create_upload_file_url($custom: create_upload_file_url_input) {
    create_upload_file_url(custom: $custom) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;
