import AccessRights from "@views/components/system/access_rights/access_rights";
import { Box, Text } from "native-base";
import React from "react";

const DashboardDetails = () => {
  return (
    <Box flex={1}>
      <Text>{"Dashboard"}</Text>
    </Box>
  );
};
export default DashboardDetails;
