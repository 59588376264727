import { Box, HStack, Text, VStack } from "native-base";
import React, { Component } from "react";
import "../../index.css";

import Scrollbars from "react-custom-scrollbars-2";

const Policy = (props) => {
  return (
    <Scrollbars>
      <Box my={5} alignItems={"center"}>
        <Text fontSize={16} bold textAlign={"center"}>
          PRIVACY POLICY
        </Text>
      </Box>
      <Box>
        <Box mx={2} my={5}>
          In AVNL Connect App, accessible from Apple Play Store/ Apple Appstore
          one of our main priorities is the privacy of our application users.
          The application provides as a means for AVNL employees to get all the
          updates of key events and news in AVNL. Any AVNL employee can share
          his views on any management decision through participating in surveys
          and he can also get to know about his PIS details. This information
          provided through these applications may not have any legal sanctity
          and are for general reference only, unless otherwise specified.
          However, every effort has been made to provide accurate and reliable
          information through these applications. Users are advised to verify
          the correctness of the facts published here from the concerned
          authorities. AVNL will not be responsible for the accuracy and
          correctness of the contents available in these applications. This
          Privacy Policy document contains types of information that is
          collected and recorded by NIC and how we use it. This policy is not
          applicable to any information collected offline or via channels other
          than this mobile application.
        </Box>
        <Text mx={2} bold>
          Information we collect
        </Text>
        <Box my={5} mx={2}>
          1. The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information. When you
          register and use the Application, you generally provide (a) your name,
          email address, age, user name, password and other registration
          information; (b) download or use applications from us; (c) information
          you provide when you contact us for help; (e) information you enter
          into our system when using the Application, such as contact
          information and other details.
          <br />
          2. We may disclose User provided and Automatically Collected
          Information:
          <br /> • as required by law, such as to comply with a subpoena, or
          similar legal process;
          <br />• when we believe in good faith that disclosure is necessary to
          protect our rights, protect your safety or the safety of others,
          investigate fraud, or respond to a government request;
          <br /> • with our trusted services providers who work on our behalf,
          do not have an independent useof the information we disclose to them,
          and have agreed to adhere to the rules set forth in this privacy
          statement.
          <br />
          You can stop all collection of information by the AVNL Connect easily
          by uninstalling the AVNL connect . You may use the standard uninstall
          processes as may be available as part of your mobile device or via the
          mobile application marketplace or network.
          <br />
          3. If you contact us directly, we may receive additional information
          about you such as profile photo, the contents of the message and/or
          attachments you may send us, and any other information you may choose
          to provide.
        </Box>
        <Text bold mx={2} my={5}>
          Use of Information :
        </Text>
        The information collected by us is used for the following purposes:
        <Box my={5} mx={2}>
          <Text>1.Provide, operate, and maintain our application. </Text>
          <Text>2. Improve, personalize, and expand our application. </Text>
          <Text>3. Develop new services, features, and functionality. </Text>
        </Box>
        <Text mx={2} bold>
          Log Files
        </Text>
        <Box my={5} mx={2}>
          AVNL Connect App follows a standard procedure of using log files.
          These files log visitors when they visit the mobile application. The
          information collected by log files include internet protocol (IP)
          addresses, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of touches/clicks. These
          are not linked to any information that is personally identifiable. The
          purpose of the information is for analysing trends, administering the
          application & tracking users' movement on the mobile application.
        </Box>
        <Box my={5} mx={2}>
          We will retain User provided data for as long as you use the AVNL
          Connect application and for a reasonable time thereafter. We will
          retain automatically collected information also for a reasonable
          period of time depending on the nature of application and thereafter
          may store it in aggregate. Please note that some or all of the User
          provided data may be required in order for the AVNL Connect
          Application to function properly.
        </Box>
        <Text my={2} mx={2} bold>
          Misuse by Non Targeted Users
        </Text>
        <Text my={2} mx={2}>
          All mobile apps are meant for use by AVNL employees only. Misuse by
          non-AVNL users should be prevented by owner of the mobile.
        </Text>
        <Text my={2} mx={2} bold>
          Security
        </Text>
        <Text my={2} mx={2}>
          We are concerned about safeguarding the confidentiality of your
          information. We provide physical, electronic, and procedural
          safeguards to protect information we process and maintain. For
          example, we limit access to this information to authorized employees
          only who need to know that information in order to operate, develop or
          improve our AVNL Connect mobile application. Please be aware that,
          although we endeavour to provide reasonable security for information
          we process and maintain, no security system can prevent all potential
          security breaches.
        </Text>
        <Text mx={2} bold>
          Changes
        </Text>
        <Text my={2} mx={2}>
          This Privacy Policy may be updated from time to time for any reason.
          We will notify you of any changes to our Privacy Policy by posting the
          new Privacy Policy here. You are advised to consult this Privacy
          Policy regularly for any changes, as continued use is deemed approval
          of all changes. You can check the history of this policy by clicking
          here.
        </Text>
        <Text mx={2} bold>
          Your Consent
        </Text>
        <Text my={2} mx={2}>
          By using the Application, you are consenting to our processing of your
          information as set forth in this Privacy Policy now and as amended by
          us.
        </Text>
        <Text mx={2} bold>
          Contact us
        </Text>
        <Text my={2} mx={2}>
          If you have any questions regarding privacy while using the
          Application, or have questions about our practices, please contact us
          via email at info@avnl.co.in
        </Text>
      </Box>
    </Scrollbars>
  );
};

export default Policy;
