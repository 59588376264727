import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import logo from "@assets/icons/avnl_logo_1.png";

import {
  Button,
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";
import BackgroundLogo from "@assets/icons/armed.JPG";
import Logo from "@assets/icons/avnl_logo_1.png";

import { useTranslation } from "react-i18next";
import AdminLoginForm from "./admin_login_form";
import { dynamicRequest, get_captcha_query } from "@services/redux";
import { useDispatch } from "react-redux";

const AdminLogin = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    get_captcha_value();
  }, []);

  const get_captcha_value = () => {
    let keys = [{ key: "request_captcha", loading: true }];
    let query = get_captcha_query;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables));
  };
  
  return (
    <>
      <Box
        safeAreaTop
        _light={{
          bg: "primary.900",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center
        height={200}
        my="auto"
        _dark={{
          bg: "#231f20",
        }}
        _light={{
          bg: "#231f20",
        }}
        bgImage={`url(${BackgroundLogo})`}
        style={{
          backgroundSize: "cover",
        }}
        flex="1"
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "1016px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
          opacity={0.9}
        >
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <HStack alignItems="center">
                <Image
                  cursor={"pointer"}
                  px={2}
                  py={2}
                  size="240px"
                  borderRadius={"120px"}
                  alt="logo"
                  resizeMode={"cover"}
                  source={logo}
                />

                <Text
                  textAlign={"center"}
                  fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
                  fontWeight="bold"
                  color="#024724"
                >
                  {t("avnl")}
                </Text>
              </HStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="#231f20"
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <Image
                width={80}
                height={80}
                alt="NativeBase Startup+ "
                resizeMode={"cover"}
                source={Logo}
                borderTopLeftRadius={20}
                borderBottomLeftRadius={20}
              />
            </Center>
          </Hidden>
          <AdminLoginForm props={props} />
        </Stack>
      </Center>
    </>
  );
};

export default AdminLogin;
