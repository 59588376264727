import DesignationList from "@views/components/user/designations/designation_list";
import { Box } from "native-base";
import React from "react";

const Designations = () => {
  return (
    <Box flex={1}>
      <DesignationList />
    </Box>
  );
};
export default Designations;
