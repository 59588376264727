import React from "react";
import { Button, Col, Form, Row } from "antd";
import { Select } from "antd";
import { Box, HStack } from "native-base";
import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EventTitle from "./events_form_elements/event_title";
import EventDescription from "./events_form_elements/event_description";
import EventUnit from "./events_form_elements/event_unit";
import EventDepartment from "./events_form_elements/event_department";
import EventDesignation from "./events_form_elements/event_designation";
import EventEmployee from "./events_form_elements/event_employee";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  create_event_mutation,
  department_list,
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  employee_list,
  get_all_designation,
  get_all_units,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import EventAudience from "./events_form_elements/event_audience";
import { getUUID, navigateBack } from "@helpers/functions";
import CustomSelect from "../custom_selects/custom_selects";
import toast from "react-hot-toast";
const { Option } = Select;

const EventsUploadDetails = () => {
  const [initial_data, setInitialData] = useState({});

  const [selectedValue, setSelectedValue] = useState(null);
  const [showUnitSelect, setShowUnitSelect] = useState(false);
  const [showDepartmentSelect, setShowDepartmentSelect] = useState(false);
  const [showDesignationSelect, setShowDesignationSelect] = useState(false);
  const [showEmployeeSelect, setShowEmployeeSelect] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { status: create_event_status, loading: create_event_loading } =
    useDynamicSelector("create_event");

  const { items: unit_items } = useDynamicSelector("get_unit_list");
  const { items: department_lists } = useDynamicSelector("get_department_list");
  const { items: get_all_employee_list } =
    useDynamicSelector("get_employee_list");
  const { items: designation_items } = useDynamicSelector(
    "get_designation_list"
  );

  const audience_items = [
    {
      id: "unit",
      name: "Unit",
    },
    {
      id: "designation",
      name: "Designation",
    },
    {
      id: "department",
      name: "Department",
    },
    {
      id: "employee",
      name: "Employee",
    },
  ];

  const handleFirstSelectChange = (value) => {
    setSelectedValue(value);

    // Determine which Selects to show based on the selected value
    setShowUnitSelect(
      value === "unit" ||
        value === "department" ||
        value === "designation" ||
        value === "employee"
    );
    setShowDepartmentSelect(value === "department" || value === "employee");
    setShowDesignationSelect(
      value === "designation" || value === "employee" || value === "department"
    );
    setShowEmployeeSelect(value === "employee");
  };

  const handleSaveClick = (values) => {
    console.log("Save button clicked!", values);
  };

  const history = useHistory();
  const handleClick = () => {
    history.push("/events");
  };

  const createEvent = (values) => {
    let _uuid = getUUID();
    let _temp = values?.upload?.map((x) => x?.url?.split("/"));
    let desiredValue = _temp?.map((x) => `event/${x[x?.length - 1]}`);
    if (desiredValue?.length > 0) {
      let key = [{ key: "create_event", loading: true }];
      let query = create_event_mutation;
      let variable = {
        data: {
          id: _uuid,
          title: values?.title,
          images: desiredValue,
          content: values?.content,
          notification: {
            audience: values?.audience,
            unit_ids: values?.units,
            department_ids: values?.departments,
            designation_ids: values?.designations,
            employee_ids: values?.employees,
          },
        },
      };
      dispatch(dynamicRequest(key, query, variable));
    } else {
      toast.error("Event images required!");
    }
  };

  useEffect(() => {
    if (create_event_status === "success") {
      navigateBack();
    }
  }, [create_event_status]);

  useEffect(() => {
    if (id) {
      let getOneItem = items?.find((x) => {
        return x.id === id;
      });
      setInitialData({ ...getOneItem });
    }
    form.setFieldsValue(initial_data);
  }, [id]);
  const handleSubmit = (values) => {
    createEvent(values);
  };

  const getAllEmployee = (unit_ids, department_ids) => {
    let key = [{ key: "get_employee_list", loading: true }];
    let query = employee_list;
    let variable = {
      filter: {
        unit_ids,
        department_ids,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllUnits = () => {
    let key = [{ key: "get_unit_list", loading: true }];
    let query = get_all_units;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAllDepartment = (unit_ids) => {
    let key = [{ key: "get_department_list", loading: true }];
    let query = department_list;
    let variable = {
      filter: {
        unit_ids,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllDesignation = () => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleUnitChange = (values) => {
    getAllEmployee(values);
    getAllDepartment(values);
  };
  const handleDepartmentChange = (values) => {
    getAllEmployee([], values, []);
  };
  const handleDesignationChange = (values) => {
    console.log("values", values);
  };
  const handleEmployeeChange = (values) => {
    console.log("values", values);
  };

  useEffect(() => {
    getAllDesignation();
    getAllUnits();
  }, []);
  return (
    <Box>
      <AntdDynamicForm
        layout="vertical"
        initialValues={initial_data}
        form={form}
        onSubmit={handleSubmit}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            width: "100%",
          }}
        >
          <h2>EVENTS</h2>
          <EventTitle />
          <EventDescription />
          <Row>
            <Col>
              <AntdDynamicForm.FileUpload
                field={"upload"}
                label={"Images"}
                maxCount={50}
                upload_type={"event"}
                required={true}
              />
            </Col>
          </Row>
          {/* <Row gutter={[13][13]} style={{ gap: "10px" }}>
            <Col span={11}>
              <EventAudience
                handleFirstSelectChange={handleFirstSelectChange}
                AudienceOptions={audience_items}
              />
            </Col>
          </Row>
          <Row gutter={[13][13]} style={{ gap: "10px" }}>
            {showUnitSelect && (
              <Col span={11}>
                <EventUnit UnitOptions={unit_items} />
              </Col>
            )}
            {showDepartmentSelect && (
              <Col span={11}>
                <EventDepartment DepartmentOption={department_lists} />
              </Col>
            )}
          </Row>
          <Row gutter={[13][13]} style={{ gap: "10px" }}>
            {showDesignationSelect && (
              <Col span={11}>
                <EventDesignation DesignationOption={designation_items} />
              </Col>
            )}
            {showEmployeeSelect && (
              <Col span={11}>
                <EventEmployee EmployeeOptions={get_all_employee_list} />
              </Col>
            )}
          </Row> */}
          <CustomSelect
            handleFirstSelectChange={handleFirstSelectChange}
            AudienceOptions={audience_items}
            showUnitSelect={showUnitSelect}
            showDepartmentSelect={showDepartmentSelect}
            showDesignationSelect={showDesignationSelect}
            showEmployeeSelect={showEmployeeSelect}
            unit_items={unit_items}
            department_lists={department_lists}
            designation_items={designation_items}
            get_all_employee_list={get_all_employee_list}
            handleUnitChange={handleUnitChange}
            handleDepartmentChange={handleDepartmentChange}
            handleDesignationChange={handleDesignationChange}
            handleEmployeeChange={handleEmployeeChange}
          />
        </Box>
        <HStack
          space={5}
          width={"95%"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Box marginBottom={10} alignItems={"center"}>
            <Button onClick={handleClick}>Back</Button>
          </Box>
          <Box marginBottom={10} alignItems={"center"}>
            <Button
              type="primary"
              loading={create_event_loading}
              htmlType="submit"
            >
              Send
            </Button>
          </Box>
        </HStack>
      </AntdDynamicForm>
    </Box>
  );
};
export default EventsUploadDetails;
