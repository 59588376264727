import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import {
  department_create,
  department_delete,
  department_list,
  department_update,
  dynamicClear,
  dynamicRequest,
  get_all_designation,
  get_all_units,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import Form from "@views/components/system/form";
import { Button, Input, Modal, Pagination, Table } from "antd";
import DepartmentDetails from "./department_details";
import { toast } from "react-hot-toast";
import { getUUID } from "@helpers/functions";

const DepartmentList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [is_open, setIsOpen] = useState(false);
  const [delete_id, setDeleteId] = useState("");
  const [delete_department_name, setDeleteDepartmentName] = useState("");

  const [incharge_id, setInchargeId] = useState("");
  const [get_one_id, setGetOneId] = useState("");
  const [header_type, setHeaderType] = useState("");
  const [initial_data, setInitialData] = useState({});
  const [department_data, setDepartmentData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [get_search_string, setSearchString] = useState("");
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);

  const {
    items: department_lists,
    loading,
    pagination: pagination,
  } = useDynamicSelector("get_department_list");
  const { items: unit_items } = useDynamicSelector("get_unit_list");
  const { items: designation_items } = useDynamicSelector(
    "get_designation_list"
  );
  const {
    status: create_status,
    loading: create_loading,
    error: create_error,
  } = useDynamicSelector("create_department");
  const {
    status: update_status,
    loading: update_loading,
    error: update_error,
  } = useDynamicSelector("update_department");
  const {
    status: delete_status,
    loading: delete_loading,
    error: delete_error,
  } = useDynamicSelector("delete_department");

  const createDepartment = (data) => {
    let key = [{ key: "create_department", loading: true }];
    let query = department_create;
    let variable = { data };
    dispatch(dynamicRequest(key, query, variable));
  };
  const updateDepartment = (data) => {
    let key = [{ key: "update_department", loading: true }];
    let query = department_update;
    let variable = { data, id: get_one_id };
    dispatch(dynamicRequest(key, query, variable));
  };
  const deleteDepartment = () => {
    let key = [{ key: "delete_department", loading: true }];
    let query = department_delete;
    let variable = { id: delete_id };
    dispatch(dynamicRequest(key, query, variable));
  };
  const fetchData = (page_number, page_limit) => {
    let key = [{ key: "get_department_list", loading: true }];
    let query = department_list;
    let variable = {
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const getAllDepartment = () => {
    let key = [{ key: "get_department_list", loading: true }];
    let query = department_list;
    let variable = {
      filter: { search_string: get_search_string },
      pagination: {
        page_number: current,
        page_limit: current_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const getUnits = () => {
    let key = [{ key: "get_unit_list", loading: true }];
    let query = get_all_units;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  const getDesignation = () => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  const onAdd = () => {
    setHeaderType("add");
    setIsOpen(true);
    setInitialData({});
    setGetOneId("");
  };

  const onEdit = (id) => {
    setHeaderType("edit");
    setGetOneId(id);
  };

  const onView = (id, item) => {
    handleModalOpen(item);
  };

  const onDelete = (id) => {
    console.log("onDelete", id);
  };

  const onChange = (
    page_number,
    page_limit,
    sort_key,
    sort_direction,
    search_string,
    filter
  ) => {
    console.log(
      "onPageChange",
      page_number,
      page_limit,
      sort_key,
      sort_direction,
      search_string,
      filter
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (item) => {
    setIsModalOpen(true);
    setDepartmentData(item);
  };

  const handleDeleteModalOpen = (record) => {
    setIsDeleteModalOpen(true);
    setDeleteId(record?.id);
    setDeleteDepartmentName(record);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const onFormCancel = () => {
    setIsOpen(false);
    setGetOneId("");
    setInitialData({});
  };

  const onFormChange = (values) => {
    console.log("onFormChange", values);
  };

  const onFormSubmit = (values) => {
    if (header_type === "add") {
      let uuid = getUUID();

      createDepartment({ ...values, id: uuid });
    } else {
      delete values.unit_id;
      updateDepartment({ ...values, id: get_one_id });
    }
  };
  const handleDeleteDepartment = () => {
    deleteDepartment();
  };
  useEffect(() => {
    getUnits();
    getDesignation();
  }, []);

  useEffect(() => {
    getAllDepartment({ search_string: get_search_string });
  }, [get_search_string]);

  const columns = [
    {
      title: t("departmentname"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("unit"),
      dataIndex: "",
      render: (record) => {
        return record?.unit?.name;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "20%",
      render: (record, rowIndex) => {
        return (
          <HStack>
            <Pressable
              onPress={() => {
                onEdit(record?.id);
              }}
              mx={2}
            >
              <AiOutlineEdit size={20} color="#000000" />
            </Pressable>
            <Pressable
              onPress={() => {
                handleDeleteModalOpen(record);
              }}
            >
              <AiOutlineDelete size={20} color="#000000" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];

  const form_fields = [
    {
      field: "name",
      label: t("name"),
      type: "text",
      rules: [
        { required: true, message: t("error:department_name_is_required") },
      ],
    },

    {
      field: "unit_id",
      label: t("unit"),
      type: "select",
      rules: [
        { required: true, message: t("error:department_unit_is_required") },
      ],
      options: unit_items,
      disabled: get_one_id ? true : false,
    },
    // {
    //   field: "designation_id",
    //   label: t("designation"),
    //   type: "select",
    //   rules: [
    //     {
    //       required: true,
    //       message: t("error:department_designation__is_required"),
    //     },
    //   ],
    //   options: designation_items,
    //   isMulti: true,
    // },
  ];
  useEffect(() => {
    if (get_one_id) {
      let getOneItem = department_lists?.find((x) => {
        return x.id === get_one_id;
      });

      setInitialData({ ...getOneItem });
      setIsOpen(true);
    }
  }, [get_one_id]);
  useEffect(() => {
    if (create_status === "success") {
      toast.success(t("Department Created"));
      getAllDepartment();
      onFormCancel();
      dispatch(dynamicClear("create_department"));
    } else if (create_error) {
      toast.error(create_error?.message);
      getAllDepartment();
      dispatch(dynamicClear("create_department"));
    }
    if (update_status === "success") {
      toast.success(t("Department Updated"));
      getAllDepartment();
      onFormCancel();
      dispatch(dynamicClear("update_department"));
    } else if (update_error) {
      toast.error(update_error?.message);
      getAllDepartment();
      dispatch(dynamicClear("update_department"));
    }

    if (delete_status === "success") {
      toast.success(t("Department Deleted"));
      getAllDepartment();
      onFormCancel();
      handleDeleteModalClose();

      dispatch(dynamicClear("delete_department"));
    } else if (delete_error) {
      toast.error(delete_error?.message);
      getAllDepartment();
      dispatch(dynamicClear("delete_department"));
    }
  }, [
    create_status,
    create_error,
    update_status,
    update_error,
    delete_status,
    delete_error,
  ]);

  const handleSearch = (value) => {
    setSearchString(value);
  };

  return (
    <Box flex={1}>
      <Box padding={5}>
        <Box py="20px">
          <Text fontSize={20} bold>
            {t("departments")}
          </Text>
        </Box>
        <HStack mb={5} justifyContent={"flex-end"} mx={5} space={3}>
          <Box width={"200px"}>
            <Input.Search label="Search" allowClear onSearch={handleSearch} />
          </Box>
          <Box>
            <Button type="primary" onClick={onAdd}>
              {t("add_department")}
            </Button>
          </Box>
        </HStack>
        <Table
          dataSource={department_lists}
          columns={columns}
          loading={loading}
          onChange={onChange}
          pagination={false}
        />
        <Box alignItems={"flex-end"} my={5}>
          <HStack alignItems={"center"}>
            <Text mx={2} fontSize="md">
              {`Total ${
                pagination?.total_count ? pagination?.total_count : 0
              } Items`}
            </Text>
            <Pagination
              showSizeChanger={true}
              hideOnSinglePage={false}
              onChange={fetchData}
              total={pagination?.total_count}
              pageSizeOptions={[5, 10, 15, 20, 50]}
            />
          </HStack>
        </Box>
      </Box>
      <Form
        title={
          header_type === "edit" ? t("edit_department") : t("add_department")
        }
        submitLabel="Save"
        isSubmitLoading={create_loading || update_loading}
        isOpen={is_open}
        width={500}
        initialData={initial_data}
        fields={form_fields}
        onCancel={onFormCancel}
        onSubmit={onFormSubmit}
        onChange={onFormChange}
      />
      <Modal
        title={t("preview")}
        open={is_modal_open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleModalClose();
              }}
            >
              {t("back")}
            </Button>
            <Button type="primary" loading={false} onClick={() => {}}>
              {t("send")}
            </Button>
          </HStack>
        }
        width={500}
        style={{
          borderRadius: 50,
        }}
      >
        <DepartmentDetails
          handleModalClose={handleModalClose}
          department_item={department_data}
        />
      </Modal>

      <Modal
        width={450}
        open={isDeleteModalOpen}
        onCancel={handleDeleteModalClose}
        onOk={() => {}}
        title={t("confirmation")}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleDeleteModalClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              loading={delete_loading}
              onClick={() => {
                handleDeleteDepartment();
              }}
            >
              {t("delete")}
            </Button>
          </HStack>
        }
      >
        <Text>
          {t("are_you_sure_you_want_to_delete_this_department", {
            name: delete_department_name?.name,
          })}
        </Text>
      </Modal>
    </Box>
  );
};
export default DepartmentList;
