import AnnouncementList from "@views/components/user/announcements/announcement_list";
import { Box } from "native-base";
import React from "react";

const Announcements = () => {
  return (
    <Box flex={1}>
      <AnnouncementList />
    </Box>
  );
};
export default Announcements;
