import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import React from "react";

const CircularTitle = () => {
  return (
    <AntdDynamicForm.TextBox
      label="Circular title"
      field={"circular_title"}
      rules={[
        {
          required: true,
          message: "Title is required!",
        },
      ]}
    />
  );
};
export default CircularTitle;
