import { gql } from "@apollo/client";
export const department_list = gql`
  query get_department_list(
    $filter: get_department_list_filter_input
    $pagination: pagination_input
    $sort: sort_input
  ) {
    get_department_list(filter: $filter, pagination: $pagination, sort: $sort) {
      items {
        id
        name
        unit_id
        unit {
          id
          name
          unit_admins {
            system_user {
              roles {
                id
                name
              }
              username
            }
          }
          designations {
            id
            name
          }
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const department_create = gql`
  mutation create_department($data: create_department_input!) {
    create_department(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const department_update = gql`
  mutation update_department($id: String!, $data: update_department_input!) {
    update_department(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const department_delete = gql`
  mutation delete_department($id: String!) {
    delete_department(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
