import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import React from "react";

const EventDescription = (props) => {
  const { options } = props;
  return (
    <AntdDynamicForm.RichText
      label="Description"
      field={"content"}
      rules={[
        {
          required: true,
          message: "event_description required!",
        },
      ]}
    />
  );
};

export default EventDescription;
