import { gql } from "@apollo/client";

export const getSurveyList = gql`
  query get_survey_list(
    $filter: get_survey_list_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_survey_list(filter: $filter, pagination: $pagination, sort: $sort) {
      items {
        status
        id
        created_at
        title
        is_responded

        questions {
          id
          total_respondents
          my_response_option_id
          content
          survey_id
          options {
            id
            option
            score
          }
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const create_survey_mutation = gql`
  mutation create_survey($data: create_survey_input) {
    create_survey(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const publish_survey_mutation = gql`
  mutation publish_survey($custom: publish_survey_input) {
    publish_survey(custom: $custom) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_survey_query = gql`
  query get_survey($id: String!) {
    get_survey(id: $id) {
      status
      id
      created_at
      title
      questions {
        id
        survey {
          status
        }
        survey_id
        options {
          sort_order
          option
          score
        }
        content
        total_respondents
      }
      is_responded
    }
  }
`;
