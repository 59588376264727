import React, { useEffect, useState } from "react";
import { Box, Center, HStack, Text, VStack } from "native-base";
import { useDispatch } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { dynamicSet, useDynamicSelector } from "@services/redux";
import ManagePageComponent from "../page_component/manage_page_component";
import PageComponentList from "../page_component/page_component_list";
import PageRoleCheckBox from "./page_role_check_box";

const PageItem = (props) => {
  const { item, index } = props;
  const dispatch = useDispatch();

  const { items: roles } = useDynamicSelector("roles");
  const [on_hover, setOnHover] = useState(false);
  console.log("roles", roles);
  const openEdit = () => {
    dispatch(dynamicSet("page_item", item));
    dispatch(dynamicSet("page_action", "Edit"));
  };

  const openDelete = () => {
    dispatch(dynamicSet("page_item", item));
    dispatch(dynamicSet("page_action", "Delete"));
  };

  return (
    <HStack>
      <VStack>
        <HStack>
          <Center
            key={index}
            w="200px"
            h="40px"
            bg="red.200"
            position="sticky"
            left="0px"
            zIndex={"3"}
            borderBottomWidth="1px"
            borderLeftWidth="1px"
            borderRightWidth={"1px"}
            borderBottomColor="black"
            borderLeftColor="black"
            borderRightColor="black"
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
          >
            <Text w="180px" color="black">
              {item.name}
            </Text>
            {on_hover && (
              <HStack space={3} position="absolute" top="3px" right="3px">
                <ManagePageComponent page_id={item.id} />
                <EditOutlined
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={openEdit}
                />
                <DeleteOutlined
                  style={{ cursor: "pointer", color: "Red" }}
                  onClick={openDelete}
                />
              </HStack>
            )}
          </Center>
          {roles &&
            roles.map((role, role_index) => (
              <Center
                key={role_index}
                w="150px"
                h="40px"
                bg="#fff3d4"
                borderTopWidth="0px"
                borderBottomWidth="1px"
                borderRightWidth={"1px"}
                borderTopColor="black"
                borderBottomColor="black"
              >
                <PageRoleCheckBox page={item} role={role} />
              </Center>
            ))}
        </HStack>
        <PageComponentList page_components={item.page_components} />
      </VStack>
    </HStack>
  );
};
export default PageItem;
