import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import {
  department_list,
  dynamicClear,
  dynamicRequest,
  employee_create,
  employee_delete,
  employee_list,
  employee_update,
  get_all_designation,
  get_all_units,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import Form from "@views/components/system/form";
import { toast } from "react-hot-toast";
import {
  Button,
  Col,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";
import EmployeeDetails from "./employee_details";
import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import { getUUID } from "@helpers/functions";
import moment from "moment";
const EmployeeList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [is_open, setIsOpen] = useState(false);
  const [delete_id, setDeleteId] = useState("");
  const [delete_employee_name, setDeleteEmployeeName] = useState("");

  const [incharge_id, setInchargeId] = useState("");
  const [get_one_id, setGetOneId] = useState("");
  const [header_type, setHeaderType] = useState("");
  const [initial_data, setInitialData] = useState({});
  const [employee_data, setEmployeeData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [get_search_string, setSearchString] = useState("");
  const [departments, setDepartments] = useState("");
  const [designations, setDesignations] = useState("");

  const {
    items: get_all_employee_list,
    loading,
    pagination,
  } = useDynamicSelector("get_employee_list");
  const { items: department_items } = useDynamicSelector("get_department_list");
  const { items: unit_items } = useDynamicSelector("get_unit_list");
  const { items: designation_items } = useDynamicSelector(
    "get_designation_list"
  );
  const {
    status: create_status,
    loading: create_loading,
    error: create_error,
  } = useDynamicSelector("create_employee");
  const {
    status: update_status,
    loading: update_loading,
    error: update_error,
  } = useDynamicSelector("update_employee");
  const {
    status: delete_status,
    loading: delete_loading,
    error: delete_error,
  } = useDynamicSelector("delete_employee");

  const createEmployee = (data) => {
    let key = [{ key: "create_employee", loading: true }];
    let query = employee_create;
    let variable = { data };
    dispatch(dynamicRequest(key, query, variable));
  };
  const updateEmployee = (data) => {
    let key = [{ key: "update_employee", loading: true }];
    let query = employee_update;
    let variables = { data, id: get_one_id };
    dispatch(dynamicRequest(key, query, variables));
  };
  const deleteEmployee = (id) => {
    let key = [{ key: "delete_employee", loading: true }];
    let query = employee_delete;
    let variable = { id: id };
    dispatch(dynamicRequest(key, query, variable));
  };
  const getAllEmployee = (search_string) => {
    let key = [{ key: "get_employee_list", loading: true }];
    let query = employee_list;
    let variable = {
      filter: {
        search_string: get_search_string,
        department_ids: departments ? departments : null,
        designation_ids: designations ? designations : null,
      },
      pagination: {
        page_number: current,
        page_limit: current_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const fetchData = (page_number, page_limit) => {
    let key = [{ key: "get_employee_list", loading: true }];
    let query = employee_list;
    let variable = {
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const getAllDepartment = () => {
    let key = [{ key: "get_department_list", loading: true }];
    let query = department_list;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };
  const getUnits = () => {
    let key = [{ key: "get_unit_list", loading: true }];
    let query = get_all_units;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  const getDesignation = () => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  const onAdd = () => {
    setHeaderType("add");
    setIsOpen(true);
    setInitialData({});
    setGetOneId("");
  };

  const onEdit = (id) => {
    setHeaderType("edit");
    setGetOneId(id);
  };

  const onView = (id, item) => {
    handleModalOpen(item);
  };
  const onDelete = () => {
    deleteEmployee(delete_id);
  };
  useEffect(() => {
    getAllEmployee();
    getAllDepartment();
    getUnits();
    getDesignation();
  }, []);

  const onChange = (
    page_number,
    page_limit,
    sort_key,
    sort_direction,
    search_string,
    filter
  ) => {
    setCurrent(page_number);
    setCurrentLimit(page_limit);
    // if (search_string) {
    //   getAllEmployee(search_string);
    // } else if (filter) {
    //   getAllEmployee("", filter);
    // } else {
    //   getAllEmployee();
    // }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (item) => {
    setIsModalOpen(true);
    setCircularData(item);
  };

  const handleDeleteModalOpen = (record) => {
    setIsDeleteModalOpen(true);
    setDeleteId(record?.id);
    setDeleteEmployeeName(record);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const onFormCancel = () => {
    setIsOpen(false);
    setGetOneId("");
    setInitialData({});
  };

  const onFormChange = (values) => {
    console.log("onFormChange", values);
  };

  const onFormSubmit = (values) => {
    if (header_type === "add") {
      delete values.confirm_password;
      let uuid = getUUID();
      let splitUrl = values?.photo?.[0]?.url;

      createEmployee({ ...values, id: uuid, photo: splitUrl });
    } else {
      let splitUrl = values?.photo?.[0]?.url.split("?");
      const parts = splitUrl?.[0]?.split("/");
      const file_url = parts[parts.length - 1];

      delete values.confirm_password;
      updateEmployee({
        ...values,
        id: get_one_id,
        photo: file_url ? file_url : "",
      });
    }
  };

  const columns = [
    {
      title: t("employee_name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("designation"),
      dataIndex: "",
      render: (record) => {
        return record?.designation?.name;
      },
    },
    {
      title:"Unit",
      dataIndex: "",
      render: (record) => {
        const unitNames = record?.designation?.units?.map((unit) => unit.name).join(', ');
        return unitNames;
      },
    },
    {
      title: t("department"),
      dataIndex: "",
      render: (record) => {
        return record?.department?.name;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "20%",
      render: (record, rowIndex) => {
        return (
          <HStack>
            <Pressable
              onPress={() => {
                onEdit(record?.id);
              }}
              mx={2}
            >
              <AiOutlineEdit size={20} color="#000000" />
            </Pressable>
            <Pressable
              onPress={() => {
                handleDeleteModalOpen(record);
              }}
            >
              <AiOutlineDelete size={20} color="#000000" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];

  const form_fields = [
    {
      field: "name",
      label: t("full_name"),
      type: "text",
      rules: [
        { required: true, message: t("error:employee_name_is_required") },
      ],
    },
    {
      field: "mobile",
      label: t("mobile_number"),
      type: "text",
      rules: [
        {
          required: false,
          message: t("error:employee_mobile_number_is_required"),
        },
        {
          pattern: new RegExp("^[6-9][0-9]{9}$"),

          message: "Enter Valid Mobile Number",
        },
      ],
    },

    {
      field: "date_of_birth",
      label: t("date_of_birth"),
      type: "datetime",
      rules: [
        {
          required: true,
          message: t("error:employee_date_of_birth_is_required"),
        },
      ],
    },

    {
      field: "email_id",
      label: t("email_id "),
      type: "text",
      rules: [
        { required: true, message: t("error:employee_email_id_is_required") },
      ],
    },

    // {
    //   field: "unit_id",
    //   label: t("unit"),
    //   type: "select",
    //   rules: [
    //     { required: true, message: t("error:employee_unit_is_required") },
    //   ],
    //   options: unit_items,
    //   isMulti: true,
    // },
    {
      field: "designation_id",
      label: t("designation"),
      type: "select",
      rules: [
        {
          required: true,
          message: t("error:employee_designation_is_required"),
        },
      ],
      options: designation_items,
    },

    {
      field: "department_id",
      label: t("department"),
      type: "select",
      // rules: [
      //   { required: true, message: t("error:employee_department_is_required") },
      // ],
      options: department_items,
    },

    // {
    //   field: "user_name",
    //   label: t("user_name"),
    //   type: "text",
    //   rules: [
    //     { required: true, message: t("error:employee_user_name_is_required") },
    //   ],
    //   isMulti: true,
    // },
    {
      field: "password",
      label: t("password"),
      type: "password",
      // rules: [
      //   { required: true, message: t("error:employee_password_is_required") },
      // ],
      isMulti: true,
    },
    {
      field: "confirm_password",
      label: t("confirm_password"),
      type: "password",
      // rules: [
      //   {
      //     required: true,
      //     message: t("error:employee_confirm_password_is_required"),
      //   },
      // ],
      isMulti: true,
    },
    {
      field: "photo",
      label: t("photo"),
      type: "photoUpload",
      upload_type: "employee",
      // rules: [
      //   { required: true, message: t("error:employee_photo_is_required") },
      // ],
      maxCount: 1,
    },
  ];

  useEffect(() => {
    if (get_one_id) {
      let getOneItem = get_all_employee_list?.find((x) => x?.id === get_one_id);
      setInitialData({
        ...getOneItem,
        date_of_birth: moment(new Date(getOneItem.date_of_birth)),
        photo: [
          {
            // type: "application/pdf",
            uid: "rc-upload-1686120521566-5",
            name: "announcement",
            url: getOneItem.photo,
            status: "done",
          },
        ],
      });
      setIsOpen(true);
    }
  }, [get_one_id]);
  useEffect(() => {
    if (create_status === "success") {
      toast.success(t("Employee Created"));
      getAllEmployee();
      onFormCancel();
      dispatch(dynamicClear("create_employee"));
    } else if (create_error) {
      toast.error(
        create_error?.message?.includes("prisma")
          ? "Something went wrong."
          : create_error?.message
      );
      getAllEmployee();
      dispatch(dynamicClear("create_employee"));
    }
    if (update_status === "success") {
      toast.success(t("Employee Updated"));
      getAllEmployee();
      onFormCancel();
      dispatch(dynamicClear("update_employee"));
    } else if (update_error) {
      toast.error(update_error?.message)?.includes("prisma")
        ? "Something went wrong."
        : update_error?.message;
      getAllEmployee();
      dispatch(dynamicClear("update_employee"));
    }

    if (delete_status === "success") {
      toast.success(t("Employee Deleted"));
      getAllEmployee();
      onFormCancel();
      handleDeleteModalClose();

      dispatch(dynamicClear("delete_employee"));
    } else if (delete_error) {
      toast.error(
        delete_error?.message?.includes("prisma")
          ? "Something went wrong."
          : delete_error?.message
      );
      getAllEmployee();
      dispatch(dynamicClear("delete_employee"));
    }
  }, [
    create_status,
    create_error,
    update_status,
    update_error,
    delete_status,
    delete_error,
  ]);

  useEffect(() => {
    getAllEmployee({
      search_string: get_search_string,
      department_ids: departments,
      designation_ids: designations ? designations : null,
    });
  }, [current, current_limit, get_search_string, departments, designations]);

  const handleSearch = (value) => {
    setSearchString(value);
  };

  return (
    <Box flex={1}>
      <Box padding={5}>
        <Box py="20px">
          <Text fontSize={20} bold>
            {t("employees")}
          </Text>
        </Box>
        {console.log("designation_items", designation_items)}
        <HStack space={"10px"}>
          <VStack space={"10px"}>
            <Box w="200px" gap={"10px"}>
              <Text bold>Designation</Text>
              <Select
                options={designation_items?.map((x) => ({
                  label: x.name,
                  value: x.id,
                }))}
                onChange={(e) => {
                  setDesignations(e);
                }}
                allowClear
                style={{ width: "100%" }}
                // mode="multiple"
              />
            </Box>
          </VStack>
          <VStack>
            <Box w="200px" gap={"10px"}>
              <Text bold>Department</Text>
              <Select
                options={department_items?.map((x) => ({
                  label: x.name,
                  value: x.id,
                }))}
                onChange={(e) => {
                  setDepartments(e);
                }}
                allowClear
                style={{ width: "100%" }}
                // mode="multiple"
              />
            </Box>
          </VStack>
        </HStack>
        <HStack mb={5} justifyContent={"flex-end"} mx={5} space={3}>
          <Box width={"200px"}>
            <Input.Search label="Search" allowClear onSearch={handleSearch} />
          </Box>
          <Box>
            <Button type="primary" onClick={onAdd}>
              {t("add_employee")}
            </Button>
          </Box>
        </HStack>
        <Table
          dataSource={get_all_employee_list}
          columns={columns}
          loading={loading}
          onChange={onChange}
          pagination={false}
        />
        <Box alignItems={"flex-end"} my={5}>
          <HStack alignItems={"center"}>
            <Text mx={2} fontSize="md">
              {`Total ${
                pagination?.total_count ? pagination?.total_count : 0
              } Items`}
            </Text>
            <Pagination
              showSizeChanger={true}
              hideOnSinglePage={false}
              onChange={fetchData}
              total={pagination?.total_count}
              pageSizeOptions={[5, 10, 15, 20, 50]}
            />
          </HStack>
        </Box>
      </Box>

      <Form
        title={header_type === "edit" ? t("edit_employee") : t("add_employee")}
        isSubmitLoading={create_loading || update_loading}
        submitLabel="Save"
        isOpen={is_open}
        width={500}
        initialData={initial_data}
        fields={form_fields}
        onCancel={onFormCancel}
        onSubmit={onFormSubmit}
        onChange={onFormChange}
      />
      {/* <Row>
        <Col>
          <AntdDynamicForm.FileUpload
            field={"upload"}
            // label={"upload"}
            maxCount={1}
          />
        </Col>
      </Row> */}

      <Modal
        title={t("preview")}
        open={is_modal_open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleModalClose();
              }}
            >
              {t("back")}
            </Button>
            <Button type="primary" loading={false} onClick={() => {}}>
              {t("send")}
            </Button>
          </HStack>
        }
        width={500}
        style={{
          borderRadius: 50,
        }}
      >
        <EmployeeDetails
          handleModalClose={handleModalClose}
          employee_item={employee_data}
        />
      </Modal>

      <Modal
        width={450}
        open={isDeleteModalOpen}
        onCancel={handleDeleteModalClose}
        onOk={() => {}}
        title={t("Confirmation")}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleDeleteModalClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button type="primary" loading={delete_loading} onClick={onDelete}>
              {t("delete")}
            </Button>
          </HStack>
        }
      >
        <Text>
          {t("are_you_sure_you_want_to_delete_this_employees", {
            name: delete_employee_name?.name,
          })}
        </Text>
      </Modal>
    </Box>
  );
};
export default EmployeeList;
