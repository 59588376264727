import AntdDynamicForm from "@views/components/system/ui/antd_form/antd_form";
import React from "react";

const Audience = (props) => {
  const { AudienceOptions, handleFirstSelectChange } = props;
  return (
    <AntdDynamicForm.Select
      options={AudienceOptions}
      label="Audience"
      field={"audience"}
      labelCol={{ span: 2 }}
      wrapperCol={{ span: 20 }}
      onChange={handleFirstSelectChange}
      rules={[
        {
          required: true,
          message: "Audience is required!",
        },
      ]}
    />
  );
};

export default Audience;
