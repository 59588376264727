import EmployeeList from "@views/components/user/employees/employee_list";
import { Box } from "native-base";
import React from "react";

const Employee = () => {
  return (
    <Box flex={1}>
      <EmployeeList />
    </Box>
  );
};
export default Employee;
