import React, { useEffect } from "react";
import { Layout } from "antd";
import Sidebar from "@views/components/system/sidebar/sidebar";
import { Box, HStack, VStack } from "native-base";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { ROUTES } from "@views/routes/my_routes";
import {
  MdBlurCircular,
  MdEventAvailable,
  MdOutlineAdUnits,
  MdOutlineAnnouncement,
  MdOutlineDashboard,
  MdOutlineLocalFireDepartment,
  MdOutlinePeopleAlt,
  MdOutlineSpaceDashboard,
} from "react-icons/md";
import { FaHospitalUser, FaRegHospital } from "react-icons/fa";
import {
  AiOutlineBank,
  AiOutlineCalendar,
  AiOutlineFile,
  AiOutlineFileText,
  AiOutlineGlobal,
  AiOutlineMedicineBox,
  AiOutlineTeam,
  AiOutlineUser,
} from "react-icons/ai";
import HeaderBar from "@views/components/user/header_bar/header_bar";
import { useCheckLogin } from "@helpers/hooks";
import { navigate, retrieveItem } from "@helpers/functions";
import { useTranslation } from "react-i18next";
import { RiSurveyLine } from "react-icons/ri";
import { BsPerson } from "react-icons/bs";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
const { Content } = Layout;

const UserLayout = ({ children }) => {
  const { t } = useTranslation();
  const is_logged_in = useCheckLogin();
  let page_accesses = retrieveItem("page_accesses");
  console.log("page_accesses", page_accesses);
  const menu_list = [
    // {
    //   key: "Dashboard",
    //   icon: <MdOutlineDashboard />,
    //   label: t("dashboard"),
    //   url: ROUTES.DASHBOARD,
    //   // accessKey: "Dashboard",
    // },
    {
      key: "Announcements",
      icon: <MdOutlineAnnouncement />,
      label: t("announcements"),
      url: ROUTES.ANNOUNCEMENTS,
      // accessKey: "Announcements",
    },
    {
      key: "Circulars",
      icon: <MdBlurCircular />,
      label: t("circulars"),
      url: ROUTES.CIRCULARS,
      // accessKey: "Circulars",
    },
    {
      key: "Events",
      icon: <MdEventAvailable />,
      label: t("events"),
      url: ROUTES.EVENTS,
      // accessKey: "Events",
    },
    {
      key: "Survey",
      icon: <RiSurveyLine />,
      label: t("surveys"),
      url: ROUTES.SURVEY,
      // accessKey: "Survey",
    },
    {
      key: "Units",
      icon: <MdOutlineAdUnits />,
      label: t("units"),
      url: ROUTES.UNITS,
      // accessKey: "Units",
    },
    {
      key: "Department",
      icon: <HiOutlineOfficeBuilding />,
      label: t("departments"),
      url: ROUTES.DEPARTMENT,
      // accessKey: "Department",
    },
    {
      key: "Designation",
      icon: <AiOutlineFileText />,
      label: t("designations"),
      url: ROUTES.DESIGNATION,
      // accessKey: "Designation",
    },
    {
      key: "Employees",
      icon: <BsPerson />,
      label: t("employees"),
      url: ROUTES.EMPLOYEE,
      // accessKey: "Employees",
    },
  ];
  useEffect(() => {
    if (is_logged_in === false) {
      navigate(ROUTES.LOGIN);
    }
  }, [is_logged_in]);
  return (
    <Layout>
      <HeaderBar />

      <HStack flex={1}>
        <Sidebar menu_list={menu_list} />
        <VStack flex={1} overflowY={"scroll"}>
          <Content>{children}</Content>
        </VStack>
      </HStack>
    </Layout>
  );
};
export default UserLayout;
