import SurveyList from "@views/components/user/survey/survey_list";
import { Box } from "native-base";
import React from "react";

const Survey = () => {
  return (
    <Box flex={1}>
      <SurveyList />
    </Box>
  );
};
export default Survey;
