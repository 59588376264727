import CircularsList from "@views/components/user/circulars/circulars_list";
import EventsList from "@views/components/user/events/events_list";
import { Box } from "native-base";
import React from "react";

const Events = () => {
  return (
    <Box flex={1}>
      <EventsList />
    </Box>
  );
};
export default Events;
