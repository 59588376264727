import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import {
  create_unit_mutation,
  delete_unit_mutation,
  dynamicClear,
  dynamicRequest,
  get_all_designation,
  get_all_units,
  update_unit_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import Form from "@views/components/system/form";
import { Button, Input, Modal, Pagination, Table } from "antd";
import UnitDetails from "./unit_details";
import { getUUID } from "@helpers/functions";
import { toast } from "react-toastify";

const UnitList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [is_open, setIsOpen] = useState(false);
  const [delete_id, setDeleteId] = useState("");
  const [delete_unit_name, setDeleteUnitName] = useState("");
  const [incharge_id, setInchargeId] = useState("");
  const [get_one_id, setGetOneId] = useState("");
  const [header_type, setHeaderType] = useState("");
  const [initial_data, setInitialData] = useState({});
  const [units_data, setUnitsData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [get_search_string, setSearchString] = useState("");

  const {
    status: create_unit_status,
    error: create_unit_error,
    loading: create_unit_loading,
  } = useDynamicSelector("create_unit");
  const {
    status: update_unit_status,
    error: update_unit_error,
    loading: update_unit_loading,
  } = useDynamicSelector("update_unit");
  const {
    status: delete_unit_status,
    error: delete_unit_error,
    loading: delete_unit_loading,
  } = useDynamicSelector("delete_unit");

  const { items: designation_items } = useDynamicSelector(
    "get_designation_list"
  );
  const {
    items: unit_list_items,
    pagination: unit_list_items_pagination,
    loading: unit_list_items_loading,
  } = useDynamicSelector("get_unit_list");

  // const designation_items = [
  //   {
  //     id: "Designation 1",
  //     name: "Designation 1",
  //   },
  //   {
  //     id: "Designation 2",
  //     name: "Designation 2",
  //   },
  //   {
  //     id: "Designation 3",
  //     name: "Designation 3",
  //   },
  // ];

  const createUnit = (values) => {
    let uuid = getUUID();

    let key = [{ key: "create_unit", loading: true }];
    let query = create_unit_mutation;
    let variable = {
      data: {
        id: uuid,
        name: values?.name,
        designation_ids: values?.designation_ids,
        unit_admin: {
          username: values?.user_name,
          password: values?.password,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const updateUnit = (values) => {
    let key = [{ key: "update_unit", loading: true }];
    let query = update_unit_mutation;
    let variable = {
      id: get_one_id,
      data: {
        id: get_one_id,
        name: values?.name,
        designation_ids: values?.designation_ids,
        unit_admin: {
          password: values?.password,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const deleteUnit = () => {
    let key = [{ key: "delete_unit", loading: true }];
    let query = delete_unit_mutation;
    let variable = {
      id: delete_id,
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const fetchData = (page_number, page_limit) => {
    let key = [{ key: "get_unit_list", loading: true }];
    let query = get_all_units;
    let variable = {
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const getAllUnits = () => {
    let key = [{ key: "get_unit_list", loading: true }];
    let query = get_all_units;
    let variables = {
      filter: { search_string: get_search_string },
      pagination: {
        page_number: current,
        page_limit: current_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAllDesignation = () => {
    let key = [{ key: "get_designation_list", loading: true }];
    let query = get_all_designation;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const onAdd = () => {
    setHeaderType("add");
    setIsOpen(true);
    setInitialData({});
    setGetOneId("");
  };

  const onEdit = (id) => {
    setHeaderType("edit");
    setGetOneId(id);
  };

  const onChange = (
    page_number,
    page_limit,
    sort_key,
    sort_direction,
    search_string,
    filter
  ) => {
    console.log(
      "onPageChange",
      page_number,
      page_limit,
      sort_key,
      sort_direction,
      search_string,
      filter
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (item) => {
    setIsModalOpen(true);
    setUnitsData(item);
  };

  const handleDeleteModalOpen = (record) => {
    setIsDeleteModalOpen(true);
    setDeleteId(record?.id);
    setDeleteUnitName(record);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const onFormCancel = () => {
    setIsOpen(false);
    setGetOneId("");
    setInitialData({});
  };

  const onFormChange = (values) => {
    console.log("onFormChange", values);
  };

  const onFormSubmit = (values) => {
    console.log("onFormSubmit", values);

    if (header_type === "edit") {
      updateUnit(values);
    } else {
      createUnit(values);
    }
  };

  const columns = [
    {
      title: t("units_name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("user_name"),
      dataIndex: "",
      render: (record) => {
        return <Text>{record?.unit_admins?.[0]?.system_user?.username}</Text>;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "20%",
      render: (record, rowIndex) => {
        return (
          <HStack>
            <Pressable
              onPress={() => {
                onEdit(record?.id);
              }}
              mx={2}
            >
              <AiOutlineEdit size={20} color="#000000" />
            </Pressable>
            <Pressable
              onPress={() => {
                handleDeleteModalOpen(record);
              }}
            >
              <AiOutlineDelete size={20} color="#000000" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];

  const form_fields = [
    {
      field: "name",
      label: t("name"),
      type: "text",
      rules: [{ required: true, message: t("error:units_name_is_required") }],
    },
    {
      field: "designation_ids",
      label: t("designations"),
      type: "select",
      rules: [{ required: true, message: t("error:designation_is_required") }],
      options: designation_items,
      isMulti: true,
    },
    {
      field: "user_name",
      label: t("user_name"),
      type: "text",
      rules: [
        { required: true, message: t("error:units_user_name_is_required") },
      ],
    },
    {
      field: "password",
      label: t("password"),
      type: "password",
    },
    {
      field: "confirm_password",
      label: t("confirm_password"),
      type: "password",
    },
  ];
  useEffect(() => {
    if (get_one_id) {
      let getOneItem = unit_list_items?.find((x) => {
        return x.id === get_one_id;
      });
      setInitialData({
        ...getOneItem,
        user_name: getOneItem?.unit_admins?.[0]?.system_user?.username,
        designation_ids: getOneItem?.designations?.map((x) => x.id),
      });
      setIsOpen(true);
    }
  }, [get_one_id]);

  useEffect(() => {
    if (create_unit_status === "success") {
      toast.success(t("Unit Created"));
      dispatch(dynamicClear("create_unit"));
      onFormCancel();
      getAllUnits();
    } else if (create_unit_error) {
      toast.error(create_unit_error?.message);
      dispatch(dynamicClear("create_unit"));
    } else if (update_unit_status === "success") {
      toast.success(t("Unit Updated"));
      dispatch(dynamicClear("update_unit"));
      onFormCancel();
      getAllUnits();
    } else if (update_unit_error) {
      toast.error(update_unit_error?.message);
      dispatch(dynamicClear("update_unit"));
    } else if (delete_unit_status === "success") {
      toast.success(t("Unit Deleted"));
      dispatch(dynamicClear("delete_unit"));
      getAllUnits();
      handleDeleteModalClose();
    } else if (delete_unit_error) {
      toast.error(delete_unit_error?.message);
      dispatch(dynamicClear("delete_unit"));
    }
  }, [
    create_unit_status,
    create_unit_error,
    update_unit_status,
    update_unit_error,
    delete_unit_status,
    delete_unit_error,
  ]);

  useEffect(() => {
    getAllDesignation();
    getAllUnits();
  }, []);

  useEffect(() => {
    getAllUnits({ search_string: get_search_string });
  }, [current, current_limit, get_search_string]);

  const handleSearch = (value) => {
    setSearchString(value);
  };

  return (
    <Box flex={1}>
      <Box padding={5}>
        <Box py="20px">
          <Text fontSize={20} bold>
            {t("units")}
          </Text>
        </Box>
        <HStack mb={5} justifyContent={"flex-end"} mx={5} space={3}>
          <Box width={"200px"}>
            <Input.Search label="Search" allowClear onSearch={handleSearch} />
          </Box>
          <Box>
            <Button type="primary" onClick={onAdd}>
              {t("add_unit")}
            </Button>
          </Box>
        </HStack>

        <Table
          dataSource={unit_list_items}
          columns={columns}
          loading={unit_list_items_loading}
          onChange={onChange}
          pagination={false}
        />
        <Box alignItems={"flex-end"} my={5}>
          <HStack alignItems={"center"}>
            <Text mx={2} fontSize="md">
              {`Total ${
                unit_list_items_pagination?.total_count
                  ? unit_list_items_pagination?.total_count
                  : 0
              } Items`}
            </Text>
            <Pagination
              showSizeChanger={true}
              hideOnSinglePage={false}
              onChange={fetchData}
              total={unit_list_items_pagination?.total_count}
              pageSizeOptions={[5, 10, 15, 20, 50]}
            />
          </HStack>
        </Box>
      </Box>
      <Form
        title={header_type === "edit" ? t("edit_unit") : t("add_unit")}
        submitLabel="Save"
        isSubmitLoading={create_unit_loading || update_unit_loading}
        isOpen={is_open}
        width={500}
        initialData={initial_data}
        fields={form_fields}
        onCancel={onFormCancel}
        onSubmit={onFormSubmit}
        onChange={onFormChange}
      />
      <Modal
        title={t("preview")}
        open={is_modal_open}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleModalClose();
              }}
            >
              {t("back")}
            </Button>
            <Button type="primary" loading={false} onClick={() => {}}>
              {t("send")}
            </Button>
          </HStack>
        }
        width={500}
        style={{
          borderRadius: 50,
        }}
      >
        <UnitDetails
          handleModalClose={handleModalClose}
          units_items={units_data}
        />
      </Modal>

      <Modal
        width={450}
        open={isDeleteModalOpen}
        onCancel={handleDeleteModalClose}
        onOk={() => {
          deleteUnit();
        }}
        title={t("confirmation")}
        footer={
          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Button
              type="default"
              onClick={() => {
                handleDeleteModalClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              loading={delete_unit_loading}
              onClick={() => {
                deleteUnit();
              }}
            >
              {t("delete")}
            </Button>
          </HStack>
        }
      >
        <Text>
          {t("are_you_sure_you_want_to_delete_this_units", {
            name: delete_unit_name?.name,
          })}
        </Text>
      </Modal>
    </Box>
  );
};

export default UnitList;
