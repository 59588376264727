import UnitList from "@views/components/user/units/unit_list";
import { Box } from "native-base";
import React from "react";

const Units = () => {
  return (
    <Box flex={1}>
      <UnitList />
    </Box>
  );
};
export default Units;
