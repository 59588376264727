import { gql } from "@apollo/client";

export const get_event_list_query = gql`
  query get_event_list(
    $filter: get_event_list_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_event_list(filter: $filter, pagination: $pagination, sort: $sort) {
      items {
        id
        title
        images
        created_at
        content
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_event_query = gql`
  query get_event($id: String!) {
    get_event(id: $id) {
      id
      title
      images
      document
      created_at
    }
  }
`;

export const create_event_mutation = gql`
  mutation create_event($data: create_event_input!) {
    create_event(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
