import { gql } from "@apollo/client";

export const create_unit_mutation = gql`
  mutation create_unit($data: create_unit_input!) {
    create_unit(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const update_unit_mutation = gql`
  mutation update_unit($id: String!, $data: update_unit_input!) {
    update_unit(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_unit_mutation = gql`
  mutation delete_unit($id: String!) {
    delete_unit(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_all_units = gql`
  query get_unit_list(
    $pagination: pagination_input
    $sort: sort_input
    $filter: get_unit_list_filter_input
  ) {
    get_unit_list(pagination: $pagination, sort: $sort, filter: $filter) {
      items {
        id
        name
        unit_admins {
          system_user {
            username
          }
        }

        designations {
          units {
            id
            name
          }
          id
          name
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;
