import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
  SimpleGrid,
} from "native-base";
import Entypo from "react-native-vector-icons/dist/Entypo";
import { Button, Modal } from "antd";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  admin_login_query,
  update_user_password,
} from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { retrieveItem, storeItem } from "@helpers/functions";
import DocumentViewer from "@views/components/system/form/document_viewer";
import image from "../../../../assets/images/events-design-vector-12488777.jpg";
import { FcDocument } from "react-icons/fc";

const EventDetails = (props) => {
  const { handleModalClose, event_item } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDocOpen, setIsDocOpen] = useState(false);
  const [document_url, set_document_url] = useState("");

  const openViewDoc = (url) => {
    setIsDocOpen(true);
    set_document_url(url);
  };

  const closeViewDoc = () => {
    setIsDocOpen(false);
  };

  return (
    <Box overflow={"auto"}>
      <Box alignItems={"center"}>
        <HStack>
          {event_item?.images?.map((x) => {
            return (
              <SimpleGrid
                style={{
                  flexDirection: "row",
                }}
                columns={3}
              >
                <Pressable
                  onPress={() => {
                    openViewDoc(x);
                  }}
                >
                  <FcDocument size={50} />
                </Pressable>
              </SimpleGrid>
            );
          })}
        </HStack>
      </Box>

      <Text my={1}>
        <div dangerouslySetInnerHTML={{ __html: event_item?.content }} />
      </Text>
      <DocumentViewer
        fileUrl={document_url}
        title="Preview"
        isOpen={isDocOpen}
        onClose={closeViewDoc}
      />
    </Box>
  );
};
export default EventDetails;
